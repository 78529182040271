import { all, call, take, takeLatest, fork, select } from 'redux-saga/effects'
import * as RR from 'domain/recruiter/parsedResumes'
import { query } from 'domain/router'
import { ensureRemoveFromQueue } from 'domain/recruiter/parsedResumes'

export function* resumesPage() {
  yield all([fork(getParsedResumesList), fork(forceAddToQueue), fork(removeFromQueue)])
}

function* getParsedResumesList() {
  const { status } = yield select(query)
  const props = status
    ? { payload: { status, page: 1 }, type: RR.parsedResumesList.request }
    : undefined
  yield call(RR.ensureGetParsedResumes, props)
  yield takeLatest(RR.parsedResumesList.type, RR.ensureGetParsedResumes)
  yield take(RR.parsedResumesList.success)
}

function* forceAddToQueue() {
  yield takeLatest(RR.forceAddResumeToQueue.type, RR.ensureForceAddToQueue)
  yield take(RR.forceAddResumeToQueue.success)
}

function* removeFromQueue() {
  yield takeLatest(RR.removeResumeFromQueue.type, RR.ensureRemoveFromQueue)
  yield take(RR.removeResumeFromQueue.success)
}
