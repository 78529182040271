import React from 'react'
import Svg, { SvgType } from 'components/svgComponent/Svg'

export default function(props: SvgType) {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8753 15.1726C12.7098 16.0651 11.2578 16.6018 9.67513 16.6018C5.85091 16.6018 2.75 13.501 2.75 9.67591C2.75 5.8508 5.85091 2.75 9.67513 2.75C13.5011 2.75 16.6021 5.8508 16.6021 9.67591C16.6021 11.2583 16.0649 12.7099 15.1715 13.8761L18.9814 17.6854C19.3395 18.0434 19.3395 18.6238 18.9814 18.9818C18.6234 19.3397 18.0433 19.3397 17.6852 18.9818L13.8753 15.1726ZM14.7687 9.67591C14.7687 12.4885 12.4878 14.7685 9.67513 14.7685C6.86426 14.7685 4.58333 12.4885 4.58333 9.67591C4.58333 6.86336 6.86426 4.58333 9.67513 4.58333C12.4878 4.58333 14.7687 6.86336 14.7687 9.67591Z"
        fill="#080910"
      />
    </Svg>
  )
}
