import React from 'react'
// components
import JobForYou from 'components/svgComponent/JobForYou'
import Contact from 'components/svgComponent/Contact'
import GetHired from 'components/svgComponent/GetHired'
import HireBest from 'components/svgComponent/HireBest'
import InstantManaging from 'components/svgComponent/InstantManaging'
import Control from 'components/svgComponent/Control'
import BigCompanies from 'components/svgComponent/BigCompanies'

interface Icon {
  0: Array<
    Array<{
      name?: string
      icon?: JSX.Element
      text?: string
    }>
  >
  1: Array<
    Array<{
      name?: string
      icon?: JSX.Element
      text?: string
    }>
  >
  2: Array<
    Array<{
      name?: string
      icon?: JSX.Element
      text?: string
    }>
  >
}

export const icons: Icon = {
  2: [
    [
      {
        name: 'Hire the best',
        icon: <HireBest />,
        text:
          'SKILENT provides a service that connects HR Departments with Hiring Managers and their Recruiters to find and hire the best candidates for your job'
      },
      {
        name: 'Instant managing',
        icon: <InstantManaging />,
        text: 'Manage your calls, interviews and meetings natively within the service'
      }
    ],
    [
      {
        name: 'Control',
        icon: <Control />,
        text: 'Control your stats on the dashboard and improve them immediately!'
      },
      {
        name: 'Big companies',
        icon: <BigCompanies />,
        text: 'SKILENT is a way to connect real work experience with online hiring opportunities'
      }
    ]
  ],
  1: [
    [
      {
        name: 'Hire the best',
        icon: <HireBest />,
        text:
          'SKILENT provides a service that connects HR Departments with Hiring Managers and their Recruiters to find and hire the best candidates for your job'
      },
      {
        name: 'Instant managing',
        icon: <InstantManaging />,
        text: 'Manage your calls, interviews and meetings natively within the service'
      }
    ],
    [
      {
        name: 'Control',
        icon: <Control />,
        text: 'Control your stats on the dashboard and improve them immediately!'
      },
      {
        name: 'Big companies',
        icon: <BigCompanies />,
        text: 'SKILENT is a way to connect real work experience with online hiring opportunities'
      }
    ]
  ],
  0: [
    [
      {
        name: 'Job for you',
        icon: <JobForYou />,
        text:
          'Search for jobs selected specifically for you relaying on your skills, hourly rate and salary and location'
      },
      {
        name: 'Contact',
        icon: <Contact />,
        text:
          'SKILENT includes a comprehensive Instant Messaging and Calendaring feature to contact and conduct conversations with prospective Recruiters, Clients and Candidates'
      }
    ],
    [
      {
        name: 'Hire the Best',
        icon: <GetHired />,
        text: 'Get hired by some of the biggest companies in the world'
      },
      {}
    ]
  ]
}
