import React, { FC, memo } from 'react'
import injectSheet, { Styles } from 'react-jss'
import { Classes } from 'jss'
import cx from 'classnames'

interface PropsI {
  from: number | null
  to: number | null
  period: string
  currency: string
  classes: Classes
  className?: string
}
const SalaryRate: FC<PropsI> = ({ className, classes, currency, from, to, period }) => (
  <p className={cx(classes.rate, className)}>
    {`${currency}${from || 0}${to ? `  -  ${to}` : ''}`}
    <span className={classes.period}> /{period}</span>
  </p>
)

const sheet: Styles = {
  rate: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 17,
    lineHeight: '21px',
    letterSpacing: '0.01em',
    color: '#00AE23',
    margin: '0 0 8px'
  },
  period: {
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: '20px',
    color: '#888888'
  }
}

export default memo(injectSheet(sheet)(SalaryRate))
