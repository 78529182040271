import React, { Component, RefObject } from 'react'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
// components
import TableRow from './TableRow'
import Skeleton from './Skeleton'
import Filter from './Filter'
import Sidebar from 'pages/Guest/Sidebar'
import NavBar from 'pages/Guest/NavBar'
import SignUp from 'pages/Auth/SignUp'
import ModalLogin from 'components/ModalAuth'
import LogIn from 'pages/Auth/LogIn'
import InputSearch from 'pages/NavBar/InputSearch'
import MapListView from 'components/MapListView'
import I from 'immutable'
// redux
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push, Push, goBack, GoBack } from 'connected-react-router'
import {
  jobsMetaGuestSelector,
  jobsGuestSelector,
  guestJobsList,
  guestJobToFavorite,
  jobsFavoriteSelector,
  campaignsGuestSelector,
  currentCampaignGuestSelector,
  removeCurrentCampaign
} from 'domain/guestJobs'
import { loading, skeletonSelector } from 'domain/loading'
import { Waypoint } from 'react-waypoint'
import { cities, citiesSelector, clearCities, countriesSelector } from 'domain/locations'
import { FormErrors, getFormSyncErrors, getFormValues } from 'redux-form'
import { clearanceTypeSelector, ClearanceTypeT } from 'domain/constants'
// types
import {
  ComposeType,
  Sort,
  SelectListT,
  SpheresItemExist,
  MetaT,
  FormErrorsInterface,
  SpheresImmutableTItem,
  ImmutableMap
} from 'types/common'
import { Classes } from 'jss'
import { JobsFilterCandidate, JobsListCandidate } from 'types/job/jobTypesCandidate'
import { StateInterface } from 'types/state'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
import { hasMorePages } from 'lib/pagination'
import { isValidPosition } from 'lib/map'
import { toNumber } from 'lodash'
import { getCurrencySymbol } from 'lib/currency'
import RightSidebar from './RightSidebar'
import { matchRoutes } from 'domain/router'

// const TAWK_PROPERTY_ID = process.env.REACT_APP_TAWK_PROPERTY_ID || ''
// const TAWK_WIDGET_ID = process.env.REACT_APP_TAWK_WIDGET_ID || ''

// utils

interface GuestCampaign {
  name: string
  label?: string
  image?: string
  jobsCount: number
  chatPropertyId: string | null
  chatWidgetId: string | null
}

interface Props {
  push: Push
  goBack: GoBack
  list: JobsListCandidate
  classes: Classes
  isLoading: boolean
  isSkeleton: boolean
  getJobsList: (params: Params) => void
  addJobToFavorite: (id: number) => void
  meta: MetaT
  citiesList: SelectListT
  countriesList: SelectListT
  clearingCities: () => void
  getCities: ({ name }: { name: string }) => void
  formValues: JobsFilterCandidate
  formErrors: FormErrors<FormErrorsInterface>
  clearanceTypeConst: ClearanceTypeT
  favoriteJobs: number[]
  campaigns: Array<ImmutableMap<GuestCampaign>>
  currentCampaign: ImmutableMap<GuestCampaign>
  removeCurrentCampaign: () => void
  match: {
    params: {
      campaign?: string
    }
  } | null
}

interface State {
  filter: FilterType
  page: number
  order?: Sort
  isOpenLogIn: boolean
  isOpenSignUp: boolean
  isOpenMobileSidebar: boolean
  viewMode: string
  searchText: string
  isOpenRightSidebar: boolean
  firstItem: number | null
  shouldShowLiveChat: boolean
  chatPropertyId: string
  chatWidgetId: string
}

interface Params {
  order?: string
  filter?: FilterType
  page?: number
  perPage?: number
}

interface FilterType {
  countryId?: number
  cityId?: number
  tagIds?: string[]
  rateFrom?: string
  rateTo?: string
  rateCurrency?: string
  salaryFrom?: string
  salaryTo?: string
  salaryCurrency?: string
  relocation?: boolean
  employmentType?: string[]
  clearanceType?: string[]
  travel?: boolean
  citizenship?: string[]
}

const placeholderImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAgEBAVbNWukAAAAASUVORK5CYII='

class JobsList extends Component<Props, State> {
  myRef: RefObject<HTMLDivElement> = React.createRef()
  tawkMessengerRef: RefObject<TawkMessengerReact> = React.createRef<TawkMessengerReact>()
  state = {
    filter: {},
    page: 1,
    order: Sort.None,
    isOpenLogIn: false,
    isOpenSignUp: false,
    isOpenMobileSidebar: false,
    viewMode: 'mix',
    searchText: '',
    isOpenRightSidebar: false,
    firstItem: null,
    shouldShowLiveChat: false,
    chatPropertyId: '',
    chatWidgetId: ''
  }

  componentDidUpdate(prevProps: Props) {
    const currentCampaign = this.getCurrentCampaign()
    const chatPropertyId = currentCampaign && currentCampaign.get('chatPropertyId')
    const chatWidgetId = currentCampaign && currentCampaign.get('chatWidgetId')
    const shouldShowLiveChat = !!(chatPropertyId && chatWidgetId && !this.props.list.isEmpty())

    if (shouldShowLiveChat !== this.state.shouldShowLiveChat) {
      this.setState(
        {
          shouldShowLiveChat,
          chatPropertyId: chatPropertyId || '',
          chatWidgetId: chatWidgetId || ''
        },
        () => {
          if (this.state.shouldShowLiveChat && window.Tawk_API) {
            window.Tawk_API.showWidget()
          } else if (!this.state.shouldShowLiveChat && window.Tawk_API) {
            window.Tawk_API.hideWidget()
          }
        }
      )
    }
  }

  componentWillUnmount() {
    if (!this.tawkMessengerRef || !this.tawkMessengerRef.current) {
      return
    }
    try {
      if (window.Tawk_API) {
        this.tawkMessengerRef.current.hideWidget()
      }
    } catch (TawkAPIError) {
      console.warn(TawkAPIError)
    }
  }

  handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value })
  }

  toggleLogIn = () => {
    this.setState({ isOpenLogIn: !this.state.isOpenLogIn })
  }

  toggleSignUp = () => {
    this.setState({ isOpenSignUp: !this.state.isOpenSignUp })
  }

  changeSort = () => {
    const nextSortType = {
      [Sort.None]: Sort.Asc,
      [Sort.Asc]: Sort.Desc,
      [Sort.Desc]: Sort.None
    }
    this.setState(
      {
        order: nextSortType[this.state.order],
        page: 1
      },
      this.request
    )
    if (this.myRef && this.myRef.current) {
      this.myRef.current.scrollTo(0, 0)
    }
  }

  loadItems = () => {
    const page = this.state.page + 1
    this.setState({ page }, () => {
      const { meta } = this.props
      if (hasMorePages(meta)) this.request()
    })
  }

  onSubmit = (data: JobsFilterCandidate) => {
    const formErrors = this.props.formErrors
    if (formErrors && Object.keys(formErrors).length > 0) return

    const filter: FilterType = {}

    if (data.country && data.country.value) filter.countryId = data.country.value
    if (data.city && data.city.value) filter.cityId = data.city.value
    const spheres = data.spheres && data.spheres.map((item: SpheresItemExist) => `${item.id}`)
    if (spheres) filter.tagIds = spheres
    if (data.rateFrom) filter.rateFrom = data.rateFrom
    if (data.rateTo) filter.rateTo = data.rateTo
    if (data.rateCurrency && data.rateCurrency.label) filter.rateCurrency = data.rateCurrency.label
    if (data.salaryFrom) filter.salaryFrom = data.salaryFrom
    if (data.salaryTo) filter.salaryTo = data.salaryTo
    if (data.salaryCurrency && data.salaryCurrency.label) {
      filter.salaryCurrency = data.salaryCurrency.label
    }
    if (data.relocation !== undefined) {
      filter.relocation = data.relocation === 'true'
    }
    const employmentType =
      data.employmentType &&
      Object.keys(data.employmentType).filter(item => data.employmentType[item] && item !== 'other')
    if (employmentType && employmentType.length) filter.employmentType = employmentType
    const clearanceType =
      data.clearanceType &&
      Object.keys(data.clearanceType).filter(item => data.clearanceType[item] && item !== 'none')
    if (clearanceType && clearanceType.length) filter.clearanceType = clearanceType
    const citizenship =
      data.citizenship &&
      Object.keys(data.citizenship).filter(item => data.citizenship[item] && item !== 'Other')
    if (citizenship && citizenship.length) filter.citizenship = citizenship

    if (data.travel !== undefined) {
      filter.travel = data.travel === 'yes'
    }

    this.setState({ filter, page: 1 }, this.request)
    if (this.myRef && this.myRef.current) {
      this.myRef.current.scrollTo(0, 0)
    }
  }

  getParamsForRequest = () => {
    const params: Params = {}
    const { filter, page, order } = this.state
    if (filter) {
      params.filter = filter
    }
    params.page = page || 1
    params.perPage = 50
    if (order && order !== Sort.None) {
      params.order = order
    }
    return params
  }

  request = () => {
    this.props.getJobsList(this.getParamsForRequest())
  }

  addToFavorite = (jobId: number) => {
    this.props.addJobToFavorite(jobId)
  }

  toggleSidebarMenu = () => {
    this.setState(prevState => ({ isOpenMobileSidebar: !prevState.isOpenMobileSidebar }))
  }

  getJobsPositions(list: JobsListCandidate) {
    return list
      .map(job => {
        const city = job.get('city')

        if (city != null) {
          const jobTitle = job.get('title')
          const jobCity = city.get('name')
          const jobState = job.get('state')
          const jobStateText = jobState && `, ${jobState.get('abbr')}`
          const markerText = jobTitle
          const jobDescShort = job.get('desc').substring(0, 100)
          const rateFrom = job.get('rateFrom')
          const rateTo = job.get('rateTo')
          const salaryFrom = job.get('salaryFrom')
          const salaryTo = job.get('salaryTo')
          const rateCurrency = job.get('rateCurrency')
          const salaryCurrency = job.get('salaryCurrency')
          const spheres = job.get('spheres')

          const shouldShowHourlyRate = rateFrom && rateTo
          const shouldShowSalaryRate = salaryFrom && salaryTo

          const hourlyRateString = shouldShowHourlyRate
            ? `${getCurrencySymbol(rateCurrency)} ${rateFrom || 0}${
                rateTo ? ` - ${rateTo}` : ''
              } /hourly`
            : ''
          const salaryRateString = shouldShowSalaryRate
            ? `${getCurrencySymbol(salaryCurrency)} ${salaryFrom || 0}${
                salaryTo ? ` - ${salaryTo}` : ''
              }`
            : ''
          const sphereNames = spheres
            .map(
              (sphere: SpheresImmutableTItem) => `<span style="
              text-transform: capitalize;
              background: #F0F0F0;
              font: normal 500 9px/12px sans-serif;
              letter-spacing: 0.03em;
              color: #080910;
              margin: 0 5px 5px 0;
              padding: 2px 5px;
              word-break: break-all;">${sphere.get('name')}</span>`
            )
            .join('')

          const markerDesc = `
            <div>
              <p><i>${jobCity}${jobStateText}</i></p>
              ${shouldShowHourlyRate ? `<p><b>${hourlyRateString}</b> /hourly</p>` : ''}
              ${shouldShowSalaryRate ? `<p><b>${salaryRateString}</b> /annual</p>` : ''}
              <p>${jobDescShort}</p>
              <p style="display: flex;flex-direction: row;flex-wrap: wrap">${sphereNames}</p>
            </div>
          `
          return {
            lat: city.get('lat'),
            lng: city.get('lng'),
            markerText,
            markerDesc,
            city: city.get('name'),
            onClick: () => {
              this.setFirstItem(job.get('id'))
              this.addToFavorite(job.get('id'))
              this.scrollToTableTop()
              // this.toggleSignUp()
            }
          }
        } else {
          return null
        }
      })
      .toJS()
  }

  getMapStyle() {
    const { viewMode } = this.state
    let mapStyle = {}

    if (viewMode === 'mix') {
      mapStyle = { height: '60vh' }
    } else if (viewMode === 'full') {
      mapStyle = { height: '80vh' }
    }

    return mapStyle
  }

  getStartingPoint() {
    const { formValues } = this.props
    const city = formValues && formValues.city
    if (!city || !isValidPosition(city)) {
      return undefined
    }
    const markerText = city.name

    return {
      lat: toNumber(city.lat),
      lng: toNumber(city.lng),
      markerText
    }
  }

  getProximity() {
    const { formValues } = this.props
    const city = formValues && formValues.city
    if (!city || !isValidPosition(city)) {
      return 0
    }

    return 50
  }

  isFavoriteJob(jobId: number) {
    const { favoriteJobs } = this.props

    return favoriteJobs.includes(jobId)
  }

  toggleRightSidebar = () => {
    this.setState(prevState => ({ isOpenRightSidebar: !prevState.isOpenRightSidebar }))
  }

  scrollToTableTop() {
    if (this.myRef && this.myRef.current) {
      this.myRef.current.scrollTo(0, 0)
    }
  }

  setFirstItem = (firstItem: number | null) => {
    this.setState({ firstItem })
  }

  getFilteredJobList(list: JobsListCandidate): JobsListCandidate {
    const { searchText } = this.state

    return list.filter(job => {
      const titleMatch = job
        .get('title')
        .toLowerCase()
        .includes(searchText.toLowerCase())
      const descriptionMatch = job
        .get('desc')
        .toLowerCase()
        .includes(searchText.toLowerCase())
      const cityNameMatch =
        job.getIn(['city', 'name']) &&
        job
          .getIn(['city', 'name'])
          .toLowerCase()
          .includes(searchText.toLowerCase())
      const stateNameMatch =
        job.getIn(['state', 'name']) &&
        job
          .getIn(['state', 'name'])
          .toLowerCase()
          .includes(searchText.toLowerCase())
      const stateAbbrMatch =
        job.getIn(['state', 'abbr']) &&
        job
          .getIn(['state', 'abbr'])
          .toLowerCase()
          .includes(searchText.toLowerCase())
      const cityZipMatch =
        job.getIn(['city', 'zip']) &&
        job
          .getIn(['city', 'zip'])
          .toLowerCase()
          .includes(searchText.toLowerCase())
      const spheresMatch =
        job.get('spheres') &&
        job.get('spheres').some((sphere: SpheresImmutableTItem) =>
          sphere
            .get('name')
            .toLowerCase()
            .includes(searchText.toLowerCase())
        )

      return (
        titleMatch ||
        descriptionMatch ||
        cityNameMatch ||
        stateNameMatch ||
        stateAbbrMatch ||
        cityZipMatch ||
        spheresMatch
      )
    })
  }

  getCurrentCampaign() {
    const { currentCampaign } = this.props

    if (I.Map.isMap(currentCampaign) && currentCampaign.has('name')) {
      return currentCampaign
    } else {
      const params = this.props.match && this.props.match.params
      const campaignName = params && params.campaign
      const { campaigns } = this.props

      return campaigns.filter(
        (ca: ImmutableMap<GuestCampaign>) =>
          campaignName && ca.get('name').toLowerCase() === campaignName.toLowerCase()
      )[0]
    }
  }

  handleMinimize() {
    if (!this.tawkMessengerRef.current) {
      return
    } else if (window.Tawk_API) {
      this.tawkMessengerRef.current.minimize()
    }
  }

  onBeforeLoadTawkWidget() {
    if (!this.tawkMessengerRef || !this.tawkMessengerRef.current) {
      return
    }
    if (window.Tawk_API) {
      this.tawkMessengerRef.current.hideWidget()
    }
  }

  onLoadTawkWidget() {
    if (!this.tawkMessengerRef || !this.tawkMessengerRef.current) {
      return
    }

    const { shouldShowLiveChat } = this.state
    if (shouldShowLiveChat && window.Tawk_API) {
      this.tawkMessengerRef.current.showWidget()
    } else {
      this.tawkMessengerRef.current.hideWidget()
    }
  }

  renderCampaign = (campaign: ImmutableMap<GuestCampaign>, index: number) => {
    const { classes } = this.props

    return (
      <div
        key={index}
        className={classes.campaignItem}
        onClick={() => this.props.push(`/jobs/${campaign.get('name')}`)}
      >
        <img
          src={campaign.get('image') || placeholderImage}
          alt={campaign.get('name')}
          className={classes.campaignImage}
        />
        <div className={classes.campaignLabel}>
          {campaign.get('label')
            ? campaign.get('label')
            : campaign.get('name') && campaign.get('name').toUpperCase()}
          <span className={classes.campaignJobsCount}>({campaign.get('jobsCount')})</span>
        </div>
      </div>
    )
  }

  returnToCampaigns() {
    this.props.removeCurrentCampaign()
    this.props.push('/jobs')
  }

  render() {
    const {
      page,
      order,
      isOpenSignUp,
      isOpenLogIn,
      isOpenMobileSidebar,
      isOpenRightSidebar,
      firstItem,
      shouldShowLiveChat
    } = this.state
    const {
      classes,
      list,
      campaigns,
      isLoading,
      isSkeleton,
      meta,
      citiesList,
      countriesList,
      clearingCities,
      getCities,
      clearanceTypeConst
    } = this.props

    const total = (meta && !meta.isEmpty() && meta.get('total')) || 0
    const hasMoreItems = hasMorePages(meta, page)
    const viewMode = this.state.viewMode
    const mapStyle = this.getMapStyle()
    const currentCampaign = this.getCurrentCampaign()
    const chatPropertyId =
      currentCampaign && currentCampaign.get('chatPropertyId')
        ? (currentCampaign.get('chatPropertyId') as string)
        : ''
    const chatWidgetId =
      currentCampaign && currentCampaign.get('chatWidgetId')
        ? (currentCampaign.get('chatWidgetId') as string)
        : ''

    const filteredList = this.getFilteredJobList(list).sort((a, b) => {
      if (firstItem !== null) {
        if (a.get('id') === firstItem) return -1
        if (b.get('id') === firstItem) return 1
      }

      return this.isFavoriteJob(b.get('id')) ? 1 : -1
    })

    return (
      <div className={classes.main}>
        {shouldShowLiveChat && chatWidgetId && chatPropertyId && (
          <TawkMessengerReact
            propertyId={chatPropertyId}
            widgetId={chatWidgetId}
            ref={this.tawkMessengerRef}
            onBeforeLoad={() => this.onBeforeLoadTawkWidget()}
            onLoad={() => this.onLoadTawkWidget()}
          />
        )}
        {isOpenLogIn && (
          <ModalLogin modalComponent={{ component: LogIn }} toggleModal={this.toggleLogIn} />
        )}
        {isOpenSignUp && (
          <ModalLogin modalComponent={{ component: SignUp }} toggleModal={this.toggleSignUp} />
        )}
        <Sidebar
          toggleModal={this.toggleLogIn}
          isOpenMobileSidebar={isOpenMobileSidebar}
          toggleSidebarMenu={this.toggleSidebarMenu}
        />

        <div className={classes.rootContainer}>
          <NavBar
            toggleLogIn={this.toggleLogIn}
            toggleSignUp={this.toggleSignUp}
            toggleSidebarMenu={this.toggleSidebarMenu}
          />
          {isOpenMobileSidebar && (
            <div className={classes.openSidebarOverlay} onClick={this.toggleSidebarMenu} />
          )}
          {!currentCampaign && campaigns && (
            <div className={classes.root}>
              <div>
                <div className={classes.header}>
                  <h4 onClick={this.props.goBack}>Open Job Categories:</h4>
                </div>
                <div className={classes.campaignsContainer}>
                  {campaigns.map(this.renderCampaign)}
                </div>
              </div>
            </div>
          )}
          {list && currentCampaign && (
            <div className={classes.root}>
              <div>
                <div className={classes.header}>
                  <div className={classes.tab}>
                    <h4 onClick={() => this.returnToCampaigns()}>
                      {currentCampaign && currentCampaign.get('image') && (
                        <img
                          src={currentCampaign.get('image')}
                          alt={currentCampaign.get('name')}
                          className={classes.campaignImage}
                        />
                      )}
                      <span>
                        {currentCampaign
                          ? currentCampaign.get('label')
                            ? currentCampaign.get('label')
                            : currentCampaign.get('name') &&
                              currentCampaign.get('name').toUpperCase()
                          : 'Search jobs'}
                      </span>
                    </h4>
                  </div>
                  <div className={classes.info}>
                    <p>
                      <span>{`Total: ${total} results`}</span>
                      {filteredList.size !== total && (
                        <span>{` (${filteredList.size} shown)`}</span>
                      )}
                    </p>
                    <p onClick={this.changeSort}>
                      <span>Most relevant</span>
                      <span>
                        <span className={classes[order]} />
                      </span>
                    </p>
                  </div>
                </div>
                <InputSearch
                  placeholder="Search by job title, description, location, skills"
                  className={classes.searchInput}
                  defaultValue=""
                  onChange={this.handleSearchInputChange}
                />
                {isSkeleton ? (
                  <div className={classes.tableBody}>
                    <Skeleton />
                  </div>
                ) : (
                  list &&
                  !list.isEmpty() && (
                    <>
                      <MapListView
                        style={mapStyle}
                        centerPoint={this.getStartingPoint()}
                        proximity={this.getProximity()}
                        positions={this.getJobsPositions(filteredList)}
                        push={this.props.push}
                        tooltipBehavior="hover"
                        handleLoadMore={
                          hasMoreItems && viewMode === 'mix' ? this.loadItems : undefined
                        }
                        onMyLocationChosen={(lat, lng) => {
                          // TODO: send current location as part of search filter
                          // TODO: check for closest city in DB and if it is less then 100 km put it on filter
                        }}
                      />
                      <div className={classes.tableBody} ref={this.myRef}>
                        {filteredList.map((job, i: number) => (
                          <TableRow
                            push={this.props.push}
                            job={job}
                            key={i}
                            toggleLogIn={() => {
                              this.addToFavorite(job.get('id'))
                              this.toggleSignUp()
                            }}
                            highlight={this.isFavoriteJob(job.get('id'))}
                            clearanceTypeConst={clearanceTypeConst}
                          />
                        ))}
                        {hasMoreItems && (
                          <Waypoint onEnter={this.loadItems}>
                            <div>
                              <Skeleton count={1} />
                            </div>
                          </Waypoint>
                        )}
                      </div>
                    </>
                  )
                )}
              </div>
              <RightSidebar
                isOpen={isOpenRightSidebar}
                toggleSidebar={this.toggleRightSidebar}
                maxWidth={350}
              >
                {!isLoading && (
                  <Filter
                    clearingCities={clearingCities}
                    getCities={getCities}
                    countriesList={countriesList}
                    citiesList={citiesList}
                    onSubmit={this.onSubmit}
                  />
                )}
              </RightSidebar>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default compose<ComposeType<Props>>(
  connect(
    (state: StateInterface) => ({
      list: jobsGuestSelector(state),
      meta: jobsMetaGuestSelector(state),
      isLoading: loading(state),
      isSkeleton: skeletonSelector(state),
      countriesList: countriesSelector(state).toJS(),
      citiesList: citiesSelector(state).toJS(),
      formValues: getFormValues('filter-jobs-list-guest')(state),
      formErrors: getFormSyncErrors('filter-jobs-list-guest')(state),
      clearanceTypeConst: clearanceTypeSelector(state),
      favoriteJobs: jobsFavoriteSelector(state).toArray(),
      match: matchRoutes(state),
      campaigns: campaignsGuestSelector(state).toArray(),
      currentCampaign: currentCampaignGuestSelector(state)
    }),
    {
      push,
      goBack,
      removeCurrentCampaign,
      addJobToFavorite: guestJobToFavorite,
      getJobsList: guestJobsList,
      getCities: cities,
      clearingCities: clearCities
    }
  ),
  injectSheet(sheet)
)(JobsList)
