import { Styles } from 'react-jss'

export const sheet: Styles = {
  title: {},
  text: {},
  root: {
    display: 'block',
    position: 'relative',
    overflow: 'hidden'
  },
  component: {
    display: 'grid',
    padding: '6% 0',
    gridTemplateColumns: '2fr 3fr 1fr 4fr 2fr',
    borderBottom: '1px solid #DEDEDE'
  },
  leftBlock: {
    gridColumn: '2',
    '& > h3': {
      marginTop: 0
    }
  },
  rightBlock: {
    gridColumn: '4'
  },
  iconBlock: {
    display: 'flex',
    '& > div': {
      position: 'relative',
      padding: '0 1vw 0 6vw',
      flex: 1,
      '& > $title': {
        marginTop: 0,
        fontWeight: 500,
        fontSize: 17,
        color: '#080910'
      },
      '& > $text': {
        fontWeight: 400,
        fontSize: 15,
        color: '#989FA6'
      },
      '& > svg': {
        position: 'absolute',
        top: 0,
        left: '10%'
      }
    },
    '& > div:last-child': {
      paddingLeft: '6vw'
    }
  },
  candidates: {
    position: 'relative',
    '& > h3': {
      position: 'absolute',
      top: '5vw',
      left: '17vw',
      margin: 0
    }
  },
  number: {
    fontFamily: 'Roboto Condensed, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30vw',
    textAlign: 'center',
    letterSpacing: '-0.01em',
    color: '#F0F0F0',
    opacity: 0.5,
    whiteSpace: 'nowrap'
  },
  '@media (max-width: 768px)': {
    component: {
      gridTemplateColumns: '1fr 5fr 5fr 1fr'
    },
    leftBlock: {
      gridColumn: '2 / 2 span',
      margin: '0 0 9% 0'
    },
    rightBlock: {
      gridColumn: '2 / 2 span'
    },
    iconBlock: {
      '& > div': {
        padding: '0 7vw 7vw',
        '& > $title': {
          paddingLeft: '1vw'
        },
        '& > svg': {
          left: 0
        }
      }
    },
    hideBlock: {
      display: 'none'
    },
    candidates: {
      position: 'relative',
      padding: '15% 0',
      '& > h3': {
        lineHeight: '56px'
      }
    },
    number: {
      fontSize: 122,
      lineHeight: '152px'
    }
  },
  '@media (max-width: 600px)': {
    iconBlock: {
      flexDirection: 'column'
    }
  }
}
