import React, { Component } from 'react'
import { Push, push } from 'connected-react-router'
// components
import { Button } from 'components/Button'
// redux
import { compose } from 'redux'
import { connect } from 'react-redux'
import { userSelector, signOut } from 'domain/env'
import { location } from 'domain/router'
import { unreadMessagesCountSelector } from 'domain/messages'
import { candidateJobsCountSelector } from 'domain/candidate/selectors'
import { recruiterJobsCountSelector } from 'domain/recruiter/selectors'
// types
import { ButtonTypes, ComposeType, UserType } from 'types/common'
import { Classes } from 'jss'
import { StateInterface } from 'types/state'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import { RolesMenu, ItemRoleType, ServerRoles } from 'lib/roles'
import cx from 'classnames'
// icons
import LogoMenu from 'components/svgComponent/LogoMenu'
import CloseMenu from 'components/svgComponent/CloseMenu'
import OpenMenu from 'components/svgComponent/OpenMenu'
import SmallLogoMenu from 'components/svgComponent/SmallLogoMenu'
import { SKILENT_VERSION } from 'lib/variables'

interface OwnProps {
  isOpenMobileSidebar: boolean
  toggleSidebarMenu: () => void
}

interface Props extends OwnProps {
  classes: Classes
  push: Push
  user: UserType
  unreadMessagesCount: number
  candidateJobsCount: number
  recruiterJobsCount: number
  location: {
    pathname: string
  }
}

interface State {
  menuIsOpen: boolean
}

class Sidebar extends Component<Props, State> {
  state = {
    menuIsOpen: true
  }

  componentDidMount(): void {
    if (window.innerWidth < 992 && window.innerWidth >= 768) {
      this.setState({ menuIsOpen: false })
    }
  }

  openCloseMenu = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen })
  }

  checkActive = (path: string) => {
    const {
      location: { pathname }
    } = this.props
    return pathname === path
  }

  parseJobsCount = (): number => {
    const { user, candidateJobsCount, recruiterJobsCount } = this.props
    const jobsByRole: { [key: string]: number } = {
      candidate: candidateJobsCount,
      recruiter: recruiterJobsCount
    }

    return jobsByRole[user.get('role')] || 0
  }

  getCount = (item: ItemRoleType) => {
    const { unreadMessagesCount } = this.props
    if (item.name === 'Messages') {
      return unreadMessagesCount
    }
    if (item.name === 'Jobs') {
      return this.parseJobsCount()
    }
    return item.count
  }

  render() {
    const { classes, user, isOpenMobileSidebar, toggleSidebarMenu } = this.props
    const { menuIsOpen } = this.state
    let role = user.get('role')
    const hasManagers = user.get('hasManagers')
    if (role === ServerRoles.recruiter && !hasManagers) {
      role = ServerRoles.recruiterWithOutManagers
    }
    const skilentVersion = SKILENT_VERSION ? `v${SKILENT_VERSION}` : '3.0'

    return (
      <div
        className={cx(classes.root, {
          [classes.rootSmall]: !menuIsOpen,
          [classes.rootMobileOpen]: isOpenMobileSidebar
        })}
      >
        <div className={classes.logo}>
          {menuIsOpen ? (
            <>
              <LogoMenu onClick={() => this.props.push('/')} />
              <div className={classes.versionText}>{skilentVersion}</div>
            </>
          ) : (
            <>
              <SmallLogoMenu onClick={() => this.props.push('/')} />
              <div className={classes.versionText}>{skilentVersion}</div>
            </>
          )}
        </div>
        {menuIsOpen ? (
          <div className={classes.menu}>
            <div>Menu</div>
            <CloseMenu onClick={this.openCloseMenu} />
          </div>
        ) : (
          <div className={classes.menu}>
            <OpenMenu onClick={this.openCloseMenu} />
          </div>
        )}
        <div className={classes.menuItems} onClick={toggleSidebarMenu}>
          {RolesMenu[role].map((item, i) => {
            const count = this.getCount(item)
            const isActive = this.checkActive(item.to)
            return (
              <div
                className={cx(classes.item, {
                  [classes.active]: isActive
                })}
                key={i}
              >
                <Button
                  buttonStyle={true}
                  isLink={true}
                  type={ButtonTypes.Menu}
                  Icon={item.icon}
                  iconActive={this.checkActive(item.to)}
                  label={item.name}
                  showLabel={menuIsOpen}
                  to={item.to}
                />
                {Boolean(count) && (
                  <div className={classes.notification}>
                    <div className={classes.circle} />
                    {menuIsOpen && <div className={classes.count}>{count}</div>}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default compose<ComposeType<Props, OwnProps>>(
  connect(
    (state: StateInterface) => ({
      user: userSelector(state),
      unreadMessagesCount: unreadMessagesCountSelector(state),
      location: location(state),
      candidateJobsCount: candidateJobsCountSelector(state),
      recruiterJobsCount: recruiterJobsCountSelector(state)
    }),
    { signOut, push }
  ),
  injectSheet(sheet)
)(Sidebar)
