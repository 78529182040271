import React, { useState } from 'react'
// redux
import { Push } from 'connected-react-router'
// components
import { Expanded } from 'components/common/Expand'
import SalaryRate from 'components/SalaryRate'
// types
import { Classes } from 'jss'
import { ClearanceTypeT } from 'domain/constants'
import { ImmutableMap, SpheresImmutableTItem } from 'types/common'
import { JobCandidate } from 'types/job/jobTypesCandidate'
// utils
import { employmentType } from 'lib/variables'
import { getLocationString } from 'lib/helpers'
import { getCurrencySymbol } from 'lib/currency'
// styles
import injectSheet from 'react-jss'
import cx from 'classnames'
import { sheet } from './sheet'
// icons
import Relocation from 'components/svgComponent/job/Relocation'
import Schedule from 'components/svgComponent/job/Schedule'
import Travel from 'components/svgComponent/job/Travel'
import I, { List } from 'immutable'

interface RateI {
  from: number | null
  to: number | null
  currency: string
}
interface JobDataI {
  title: string
  topSecret: string | boolean
  clientName: string
  hourlyRate: RateI
  salaryRate: RateI
  textArray: string[] | string
  spheres: List<SpheresImmutableTItem>
  location: string
  employmentType: List<string>
  travel: boolean
  relocation: boolean
  favorite: boolean
}

interface Props {
  classes: Classes
  push: Push
  job: ImmutableMap<JobCandidate>
  toggleLogIn: () => void
  clearanceTypeConst: ClearanceTypeT
  highlight?: boolean
}

const TableRow = ({ classes, toggleLogIn, job, clearanceTypeConst, highlight = false }: Props) => {
  const [isCardView, setIsCardView] = useState(false)

  let jobData: JobDataI = {
    title: '',
    topSecret: '',
    clientName: '',
    hourlyRate: {} as RateI,
    salaryRate: {} as RateI,
    textArray: [],
    spheres: I.List([]),
    location: '',
    employmentType: I.List([]),
    travel: false,
    relocation: false,
    favorite: false
  }

  let shouldShowHourlyRate = false
  let shouldShowSalaryRate = false

  if (job && !job.isEmpty()) {
    const client = job.get('client')
    const clientName = client && !client.isEmpty() ? client.get('companyName') : 'Incube HUB'
    const city = job.getIn(['city', 'name'])
    const stateAbbr: string = job.getIn(['state', 'abbr'])
    const stateName: string = job.getIn(['state', 'name'])
    const country =
      job.getIn(['country', 'name']) === 'United States' && (stateAbbr || stateName)
        ? ''
        : job.getIn(['country', 'name'])
    const rateCurrency = getCurrencySymbol(job.get('rateCurrency'))
    const salaryCurrency = getCurrencySymbol(job.get('salaryCurrency'))
    const hourlyRate = {
      from: job.get('rateFrom'),
      to: job.get('rateTo'),
      currency: rateCurrency
    }
    shouldShowHourlyRate = !!(hourlyRate.from && hourlyRate.to)
    const salaryRate = {
      from: job.get('salaryFrom'),
      to: job.get('salaryTo'),
      currency: salaryCurrency
    }
    shouldShowSalaryRate = !!(salaryRate.from && salaryRate.to)
    jobData = {
      title: job.get('title'),
      topSecret:
        job.get('clearanceType') &&
        job.get('clearanceType').includes(clearanceTypeConst.get('topSecret')),
      clientName,
      hourlyRate,
      salaryRate,
      textArray: job.get('desc').split(' '),
      spheres: job.get('spheres'),
      location: getLocationString(city, country, stateAbbr || stateName),
      employmentType: job.get('employmentType'),
      travel: job.get('travel'),
      relocation: job.get('relocation'),
      favorite: job.get('isFavorite')
    }
  }
  const shortDescLength = 30

  const toggleView = () => setIsCardView(!isCardView)

  const renderTravelIcon = () => (
    <p className={classes.travel}>
      {jobData.travel ? (
        <span className={classes.tooltipText}>Requires Travel</span>
      ) : (
        <span className={classes.tooltipText}>Travel Not Required</span>
      )}
      <Travel iconActive={jobData.travel} />
    </p>
  )

  const renderRelocationIcon = () => (
    <p className={classes.relocation}>
      {jobData.relocation ? (
        <span className={classes.tooltipText}>Need Relocation</span>
      ) : (
        <span className={classes.tooltipText}>Relocation Not Needed</span>
      )}
      <Relocation iconActive={jobData.relocation} />
    </p>
  )

  return (
    <div className={cx(classes.tableRow, { [classes.highlight]: highlight })} onClick={toggleView}>
      {isCardView ? (
        <div className={classes.leftBlock}>
          <div className={classes.mainBlock}>
            <div className={classes.blockTitle}>
              <p className={classes.title}>{jobData.title}</p>
              {jobData.location && <p className={classes.location}>{jobData.location}</p>}
            </div>

            <div className={classes.rateInfo}>
              <div className={classes.rateBlock}>
                {shouldShowHourlyRate && (
                  <SalaryRate
                    currency={jobData.hourlyRate.currency}
                    from={jobData.hourlyRate.from}
                    to={jobData.hourlyRate.to}
                    period="hourly"
                  />
                )}
                {shouldShowSalaryRate && (
                  <SalaryRate
                    currency={jobData.salaryRate.currency}
                    from={jobData.salaryRate.from}
                    to={jobData.salaryRate.to}
                    period="annual"
                  />
                )}
              </div>
              <div className={classes.workType}>
                <Schedule />
                {jobData.employmentType.map((item: string) => employmentType[item]).join(', ')}
              </div>
            </div>
          </div>

          <Expanded data={jobData.textArray} limit={shortDescLength}>
            {(data: string[], expanded: boolean, toggleExpand: () => void) => {
              return (
                <p className={classes.info}>
                  {data.join(' ')}
                  {jobData.textArray.length > shortDescLength && (
                    <span onClick={() => true}>{expanded ? ' less' : ` ...more`}</span>
                  )}
                </p>
              )
            }}
          </Expanded>
          <div className={classes.row}>
            <div className={classes.spheres}>
              {jobData.spheres.map((item: SpheresImmutableTItem, i: number) => {
                return <span key={i}>{item.get('name')}</span>
              })}
            </div>
            <div>
              {renderTravelIcon()}
              {renderRelocationIcon()}
            </div>
          </div>
          <button className={classes.applyButton} onClick={toggleLogIn}>
            Apply now
          </button>
        </div>
      ) : (
        <div className={classes.compactRow}>
          <div className={classes.title}>{jobData.title}</div>
          <div className={classes.location}>
            <p className={classes.locationName}>{jobData.location}</p>
          </div>
          <div className={classes.salary}>
            {shouldShowHourlyRate && (
              <SalaryRate
                className={classes.compactRate}
                currency={jobData.hourlyRate.currency}
                from={jobData.hourlyRate.from}
                to={jobData.hourlyRate.to}
                period="hourly"
              />
            )}
            {shouldShowSalaryRate && (
              <SalaryRate
                className={classes.compactRate}
                currency={jobData.salaryRate.currency}
                from={jobData.salaryRate.from}
                to={jobData.salaryRate.to}
                period="annual"
              />
            )}
          </div>
          {renderTravelIcon()}
          {renderRelocationIcon()}
        </div>
      )}
    </div>
  )
}

export default injectSheet(sheet)(TableRow)
