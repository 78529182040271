import { Action } from 'redux'
import { JobsListCandidate } from 'types/job/jobTypesCandidate'
import I from 'immutable'
import * as Guest from 'domain/guestJobs'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  jobsList: I.List(),
  campaignsList: I.List(),
  currentCampaign: I.Map(),
  favoriteJobs: I.Set()
})

export const reducer = {
  guest(state = State, action: ActionType) {
    switch (action.type) {
      case Guest.guestNewJobsList.success:
        return state
          .set('jobsList', action.payload.get('data'))
          .set('jobsListMeta', action.payload.get('meta'))
          .update('campaignsList', (campaignsList: any) =>
            mergeCampaign(campaignsList, action.payload.getIn(['meta', 'currentCampaign']))
          )
          .set('currentCampaign', action.payload.getIn(['meta', 'currentCampaign']))
      case Guest.guestJobsList.success:
        return state
          .update('jobsList', (u: JobsListCandidate) => u.concat(action.payload.get('data')))
          .set('jobsListMeta', action.payload.get('meta'))
          .update('campaignsList', (campaignsList: any) =>
            mergeCampaign(campaignsList, action.payload.getIn(['meta', 'currentCampaign']))
          )
          .set('currentCampaign', action.payload.getIn(['meta', 'currentCampaign']))
      case Guest.guestJobsList.failure:
        return state.set('jobsList', I.List())
      case Guest.guestJobToFavorite.type:
        return state.update('favoriteJobs', (j: any) => j.add(action.payload))
      case Guest.removeCurrentCampaign.type:
        return state.set('currentCampaign', {})
      case Guest.guestCampaignsList.success:
        return state
          .set('campaignsList', action.payload.get('data'))
          .set('campaignsListMeta', action.payload.get('meta'))
      default:
        return state
    }
  }
}

function mergeCampaign(campaignsList: any, currentCampaign: any) {
  if (currentCampaign && !currentCampaign.isEmpty()) {
    const existingIndex = campaignsList.findIndex(
      (campaign: any) => campaign.get('id') === currentCampaign.get('id')
    )
    if (existingIndex >= 0) {
      return campaignsList.update(existingIndex, (campaign: any) => campaign.merge(currentCampaign))
    } else {
      return campaignsList.push(currentCampaign)
    }
  }
  return campaignsList
}
