import { all, call, take, takeLatest, fork, put, select } from 'redux-saga/effects'
import pick from 'lodash/pick'
import queryString from 'query-string'
import { replace } from 'connected-react-router'
import { isLoading } from 'domain/loading'
import * as A from 'domain/recruiter/candidates'
import * as J from 'domain/recruiter/jobs'
import * as L from 'domain/locations'
import * as R from 'domain/router'
import { jobsForFilter } from 'domain/recruiter/selectors'
import * as Actions from 'domain/recruiter/jobs/actions'
import I from 'immutable'

export interface QueryParamsI {
  job?: number
  search?: string
  countryId?: number
  countryName?: string
  cityId?: number
  proximity?: number
  cityName?: string
  stateName?: string
  rateCurrency?: string
  salaryCurrency?: string
  tagIds?: string
  scope?: string
  relocation?: string
  clearanceType?: string[]
  citizenship?: string[]
  citizenshipDesc?: string
  page?: number
  order?: string
  order_by?: string
  perPage?: string
  current?: string
  tagsMatch?: string
  workStartedFrom?: Date
  workStartedTo?: Date
}
export const PERMITTED_FILTER_FIELDS = [
  'cityId',
  'job',
  'proximity',
  'tagIds',
  'rateFrom',
  'rateTo',
  'rateCurrency',
  'salaryFrom',
  'salaryTo',
  'salaryCurrency',
  'relocation',
  'clearanceType',
  'citizenship',
  'citizenshipDesc',
  'current',
  'tagsMatch',
  'workStartedFrom',
  'workStartedTo'
]

export const PERMITTED_QUERY_PARAMS = [
  'page',
  'order',
  'order_by',
  'search',
  'scope',
  'perPage',
  'current'
]

interface LocationI {
  query: QueryParamsI
  pathname: string
}

let isFirstLoad = true
export function* candidatesPage(location: any) {
  yield all([
    fork(getCandidateList),
    fork(initialLoad, location),
    fork(saveCandidate),
    fork(getJobsListForFilter)
  ])
}

function* getCandidateList() {
  yield takeLatest(A.candidates.type, A.ensureGetCandidates)
  yield take(A.candidates.success)
}

function* saveCandidate() {
  yield takeLatest(A.candidateFavoriteFromList.type, A.ensureAddCandidateToFavoriteFromList)
  yield take(A.candidateFavoriteFromList.success)
}

function* getJobsListForFilter() {
  yield takeLatest(A.jobsListForFilter.type, A.ensureGetJobsForFilter)
  yield take(A.jobsListForFilter.success)
}

function* initialLoad({ query, pathname }: LocationI) {
  const route = yield select(R.routing)
  const params = pick(query, PERMITTED_QUERY_PARAMS)
  const filter = pick(query, PERMITTED_FILTER_FIELDS)
  const jobsList = yield select(jobsForFilter)
  const isEmptyJobsList = jobsList.isEmpty()

  try {
    if (isFirstLoad || isEmptyJobsList) {
      yield put({ type: isLoading, payload: true })
    }
    if (isFirstLoad) {
      yield call(L.ensureGetCounties)
      yield put({ type: 'profile/CLEAR_CITIES' })
      isFirstLoad = false
    }
    if (isEmptyJobsList) {
      yield call(A.ensureGetJobsForFilter)
    }
    const props = {
      payload: {
        ...params,
        page: params.page || 1,
        perPage: 10
      },
      type: A.candidates.request
    }
    // load list from the first page and change params in url after reload
    const prevLocation = Object.keys(route.prevLocation || {}).length
    if (!prevLocation || (prevLocation && route.action === 'POP' && props.payload.page !== 1)) {
      const stringifyParams = queryString.stringify(
        { ...query, page: 1 },
        { arrayFormat: 'bracket' }
      )
      yield put(replace(`${pathname}?${stringifyParams}`))
      return
    }

    yield call(A.ensureGetCandidates, {
      ...props,
      payload: {
        ...props.payload,
        filter: Object.keys(filter).length
          ? {
              ...filter,
              relocation: filter.relocation === 'true' ? 'true' : undefined,
              tagIds: filter.tagIds ? JSON.parse(filter.tagIds).map((i: any) => i.id) : undefined
            }
          : {
              current: 'available'
            }
      }
    })
    if (filter.cityId) {
      yield call(L.ensureGetCity, {
        payload: { cityId: filter.cityId }
      })
    }
    if (filter.job) {
      yield call(J.ensureGetJobById, { jobId: filter.job })
    } else {
      yield put({ type: Actions.jobData.success, payload: I.fromJS({}) })
    }
  } finally {
    yield put({ type: isLoading, payload: false })
  }
}
