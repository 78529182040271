import React, { PureComponent } from 'react'
// types
import { Styles } from 'react-jss'
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
// icons
import SearchInput from 'components/svgComponent/SearchInput'
// utils
import cx from 'classnames'

interface InputPropsType {
  classes: Classes
  placeholder: string
  className?: string
  defaultValue?: string
}

const styles: Styles = {
  inputContainer: {
    position: 'relative',
    padding: '0 0 0 20px',
    margin: '0 20px',
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    width: '100%'
  },
  icon: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    margin: '0 10px'
  },
  inputField: {
    padding: '10px 10px 10px 30px',
    outline: 'none',
    margin: 0,
    border: 'none',
    color: '#111111',
    fontSize: 15,
    fontWeight: 400,
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: 2,
    width: '100%',
    '&:focus, &:active': {
      borderColor: '#0f32c9',
      outlineColor: '#0f32c9'
    },
    '&::placeholder': {
      fontFamily: 'Roboto, sans-serif',
      color: '#888888',
      lineHeight: 18,
      fontSize: 13,
      letterSpacing: '0.03em'
    }
  }
}

type Props = InputPropsType & React.InputHTMLAttributes<HTMLInputElement>

class InputSearch extends PureComponent<Props> {
  render() {
    const { classes, placeholder, className, onChange, defaultValue } = this.props
    return (
      <div className={cx(classes.inputContainer, className)}>
        <SearchInput className={classes.icon} />
        <input
          className={classes.inputField}
          type="text"
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(InputSearch)
