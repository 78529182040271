import React, { ElementType, FC, memo } from 'react'
// redux
import { Link } from 'react-router-dom'
// types
import { ButtonTypes, ButtonHtmlTypes } from 'types/common'
import { Classes } from 'jss'
import { SvgType } from 'components/svgComponent/Svg'
// styles
import { sheet } from './sheet'
import injectSheet from 'react-jss'
// utils
import cx from 'classnames'

interface Props {
  block?: boolean
  classes: Classes
  label?: string
  className?: string
  full?: boolean
  noLine?: boolean
  htmlType?: ButtonHtmlTypes
  type?: ButtonTypes
  disabled?: boolean
  isLink?: boolean
  authLink?: boolean
  to?: string
  onClick?: (arg?: any) => void
  buttonStyle?: boolean
  Icon?: (props: SvgType) => JSX.Element
  iconActive?: boolean
  showLabel?: boolean
  form?: string
  hidden?: boolean
  id?: string
  check?: boolean
  withdraw?: boolean
  withdrawDisabled?: boolean
  title?: string
}

const ButtonComponent: FC<Props> = ({
  classes,
  isLink,
  block = false,
  full = false,
  className,
  type,
  htmlType = ButtonHtmlTypes.Button,
  noLine = true,
  disabled,
  authLink = false,
  label,
  buttonStyle = false,
  Icon,
  iconActive = false,
  showLabel = true,
  check = false,
  withdraw = false,
  withdrawDisabled = false,
  ...rest
}) => {
  const MyComponent: ElementType = isLink ? Link : 'button'
  let iconProps = {}
  if (Icon && iconActive) iconProps = { iconActive: true }
  return (
    <MyComponent
      className={cx(
        classes.btn,
        {
          [classes.link]: isLink && !buttonStyle,
          [classes.authLink]: authLink,
          [classes.default]: type === ButtonTypes.Default,
          [classes.submit]: type === ButtonTypes.Submit || type === ButtonTypes.SubmitDisabled,
          [classes.submitDisabled]: type === ButtonTypes.SubmitDisabled,
          [classes.submitUnclick]: type === ButtonTypes.SubmitUnclick,
          [classes.redButton]:
            type === ButtonTypes.RedButton || type === ButtonTypes.RedButtonDelete,
          [classes.auth]: type === ButtonTypes.Auth,
          [classes.primary]: type === ButtonTypes.Primary || type === ButtonTypes.Secondary,
          [classes.secondary]: type === ButtonTypes.Secondary,
          [classes.blueButton]: type === ButtonTypes.BlueButton,
          [classes.menu]: type === ButtonTypes.Menu,
          [classes.unsaved]: type === ButtonTypes.Unsaved,
          [classes.saved]: type === ButtonTypes.Saved,
          [classes.cancel]: type === ButtonTypes.Cancel,
          [classes.delete]: type === ButtonTypes.Delete,
          [classes.activeTab]:
            type === ButtonTypes.ActiveTab || type === ButtonTypes.ActiveTabPeriod,
          [classes.tab]:
            type === ButtonTypes.Tab ||
            type === ButtonTypes.TabPeriod ||
            type === ButtonTypes.DisabledTabPeriod,
          [classes.add]: type === ButtonTypes.Add,
          [classes.reopen]: type === ButtonTypes.Reopen,
          [classes.holdJob]: type === ButtonTypes.HoldJob || type === ButtonTypes.CloseJob,
          [classes.closeJob]: type === ButtonTypes.CloseJob,
          [classes.back]: type === ButtonTypes.Back,
          [classes.redButtonDelete]: type === ButtonTypes.RedButtonDelete,
          [classes.accept]: type === ButtonTypes.Accept,
          [classes.decline]: type === ButtonTypes.Decline,
          [classes.dashboard]: type === ButtonTypes.Dashboard,
          [classes.greenButton]: type === ButtonTypes.GreenButton,
          [classes.dateTimeEvent]: type === ButtonTypes.DateTimeEvent,
          [classes.tabPeriod]:
            type === ButtonTypes.TabPeriod ||
            type === ButtonTypes.ActiveTabPeriod ||
            type === ButtonTypes.DisabledTabPeriod,
          [classes.disabledTabPeriod]: type === ButtonTypes.DisabledTabPeriod,
          [classes.check]: check,
          [classes.withdraw]: withdraw,
          [classes.withdrawDisabled]: withdrawDisabled,
          [classes.notificationLink]: type === ButtonTypes.NotificationLink,
          [classes.notificationLinkTitle]: type === ButtonTypes.NotificationLinkTitle,
          [classes.withIcon]: !!Icon,
          [classes.disabled]: disabled || withdrawDisabled,
          [classes.dangerous]: type === ButtonTypes.Dangerous
        },
        className
      )}
      type={htmlType}
      disabled={disabled}
      {...rest}
    >
      {Icon && <Icon {...iconProps} />}
      {showLabel ? label : null}
      {!noLine && <div className={cx('line')} />}
    </MyComponent>
  )
}

export const Button = memo(injectSheet(sheet)(ButtonComponent))
