import React, { Component } from 'react'
// components
import CircleBackground from 'components/common/CircleBackground'
import SquareBackground from 'components/common/SquareBackground'
import Input from 'components/Form/Input'
import CheckBox from 'components/Form/CheckBox'
import { Button } from 'components/Button'
import { FieldInput } from 'components/Form/Fields/FieldInput'
import { FieldCheckBox } from 'components/Form/Fields/FieldCheckBox'
import { icons } from './icons'
// redux
import { compose } from 'redux'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { push, Push } from 'connected-react-router'
import { stateRouter } from 'domain/router/reducer'
import * as A from 'domain/env'
// types
import { ButtonHtmlTypes, ButtonTypes, ComposeType } from 'types/common'
import { Classes } from 'jss'
import cx from 'classnames'
import { StateInterface } from 'types/state'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import { signUpValidate } from 'pages/Auth/validation'
import { upper } from 'lib/normalize'
import { jobsFavoriteSelector } from 'domain/guestJobs'

interface FormValuesInterface {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmationPassword: string
  accept: boolean
  campaign?: string
  favoriteJobs?: number[]
}

enum StateSignUp {
  Candidate,
  NoCandidate,
  Recruiter
}

interface OwnProps {
  classes: Classes
  signUpManager: (data: FormValuesInterface) => void
  signUpRecruiter: (data: FormValuesInterface) => void
  signUpCandidate: (data: FormValuesInterface) => void
  push?: Push
  stateFromLocation?: { isCandidate: boolean }
  favoriteJobs?: number[]
}

interface State {
  selectedOption: StateSignUp
}

type Props = InjectedFormProps<FormValuesInterface> & OwnProps

class SignUp extends Component<Props, State> {
  state = {
    selectedOption:
      this.props.stateFromLocation && !this.props.stateFromLocation.isCandidate
        ? StateSignUp.NoCandidate
        : StateSignUp.Candidate
  }

  onClick = (path: string) => {
    if (this.props.push) this.props.push(path)
  }

  changeSelectedOptionCandidate = () => this.setState({ selectedOption: StateSignUp.Candidate })

  changeSelectedOptionRecruiter = () => this.setState({ selectedOption: StateSignUp.Recruiter })

  changeSelectedOptionNoCandidate = () => this.setState({ selectedOption: StateSignUp.NoCandidate })

  submit = (data: FormValuesInterface) => {
    if (this.state.selectedOption === StateSignUp.NoCandidate) {
      this.props.signUpManager(data)
    } else if (this.state.selectedOption === StateSignUp.Recruiter) {
      this.props.signUpRecruiter(data)
    } else {
      const { favoriteJobs } = this.props

      this.props.signUpCandidate({ ...data, favoriteJobs })
    }
  }

  logIn = () => {
    this.onClick('/log-in')
  }

  render() {
    const { classes, handleSubmit } = this.props
    const { selectedOption } = this.state
    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className={classes.content}>
          <div className={classes.textLeft}>
            <CircleBackground className="forLogIn" />
            <SquareBackground className="forSignUp" />
            <div className={classes.radio}>
              <input
                type="radio"
                value="1"
                id="1"
                checked={selectedOption === StateSignUp.Candidate}
                onChange={this.changeSelectedOptionCandidate}
                className={classes.radioInput}
              />
              <label htmlFor="1">
                <h3
                  className={cx({
                    [classes.activeRadio]: selectedOption === StateSignUp.Candidate
                  })}
                >
                  I’m a Candidate for a job
                </h3>
              </label>
            </div>
            <div className={classes.radio}>
              <input
                type="radio"
                value="2"
                id="2"
                checked={selectedOption === StateSignUp.Recruiter}
                onChange={this.changeSelectedOptionRecruiter}
                className={classes.radioInput}
              />
              <label htmlFor="2">
                <h3
                  className={cx({
                    [classes.activeRadio]: selectedOption === StateSignUp.Recruiter
                  })}
                >
                  I’m a Recruiter
                </h3>
              </label>
            </div>
            <div className={classes.radio}>
              <input
                type="radio"
                value="0"
                id="0"
                checked={selectedOption === StateSignUp.NoCandidate}
                onChange={this.changeSelectedOptionNoCandidate}
                className={classes.radioInput}
              />
              <label htmlFor="0">
                <h3
                  className={cx({
                    [classes.activeRadio]: selectedOption === StateSignUp.NoCandidate
                  })}
                >
                  I’m a Hiring manager
                </h3>
              </label>
            </div>
            <div className={classes.margin}>
              {icons[selectedOption].map((item, i) => (
                <div className={classes.iconBlock} key={i}>
                  {item.map((el, j) => (
                    <div key={j}>
                      {el.icon}
                      <p className={classes.title}>{el.name}</p>
                      <p className={classes.text}>{el.text}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className={classes.parent}>
            <div className={classes.form}>
              {selectedOption === StateSignUp.NoCandidate && <h4>Sign up as Hiring manager</h4>}
              {selectedOption === StateSignUp.Recruiter && <h4>Sign up as Recruiter</h4>}
              {selectedOption === StateSignUp.Candidate && <h4>Sign up as Candidate</h4>}
              <div className={classes.name}>
                <FieldInput
                  name="firstName"
                  type="text"
                  component={Input}
                  title="First name"
                  normalize={upper}
                />
                <FieldInput
                  name="lastName"
                  type="text"
                  component={Input}
                  title="Last name"
                  normalize={upper}
                />
              </div>
              <FieldInput
                name="email"
                type="text"
                component={Input}
                title="Email"
                placeholder="email@example.co"
              />
              <FieldInput
                name="password"
                type="password"
                component={Input}
                title="Password"
                placeholder="must include at least 8 symbols"
                isPassword={true}
              />
              <FieldInput
                name="confirmationPassword"
                type="password"
                component={Input}
                title="Repeat password"
                isPassword={true}
              />
              <FieldCheckBox
                name="accept"
                component={CheckBox}
                label={
                  <span className={classes.linkText}>
                    I accept{' '}
                    <a href="/terms_and_conditions" target="_blank">
                      Terms and Conditions
                    </a>
                  </span>
                }
              />
              <div className={classes.buttonGroup}>
                <Button label="Log in" onClick={this.logIn} />
                <Button
                  type={ButtonTypes.Submit}
                  htmlType={ButtonHtmlTypes.Submit}
                  label=" Create account"
                />
              </div>
              {/*<LinkedinLogo name="Sign up with Linked In" />*/}
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default compose<ComposeType<Props>>(
  connect(
    (state: StateInterface) => ({
      stateFromLocation: stateRouter(state),
      favoriteJobs: jobsFavoriteSelector(state).toArray()
    }),
    {
      signUpCandidate: A.signUpCandidate,
      signUpManager: A.signUpManager,
      signUpRecruiter: A.signUpRecruiter,
      push
    }
  ),
  reduxForm({
    form: 'sign-up',
    validate: signUpValidate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  }),
  injectSheet(sheet)
)(SignUp)
