import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const getCampaignsList = asyncAction('manager/GET_CAMPAIGNS_LIST')
export const getDomainsList = asyncAction('manager/GET_DOMAINS_LIST')
export const getCampaignData = asyncAction('manager/GET_CAMPAIGN_DATA')
export const putCampaign = createRoutine('manager/PUT_CAMPAIGN')
export const postCampaign = createRoutine('manager/POST_CAMPAIGN')
export const checkCampaignName = createRoutine('manager/CHECK_CAMPAIGN_NAME')
export const checkCampaignNamePromiseCreator = promisifyRoutine(checkCampaignName)
