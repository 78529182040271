import { Styles } from 'react-jss'

export const sheet: Styles = {
  rootSmall: {},
  root: {
    minWidth: 32,
    overflowY: 'auto',
    minHeight: '100vh',
    position: 'relative',
    transition: 'max-width 300ms ease',
    '&$rootSmall': {
      maxWidth: 32,
      height: 80,
      '& $toggleButton': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 10px',
        width: '100%'
      }
    }
  },
  rootOpen: {
    maxWidth: (props: any) => props.maxWidth
  },
  toggleButton: {
    position: 'absolute',
    top: 5,
    right: 0,
    border: 'none',
    color: '#989FA6',
    padding: '5px 10px',
    cursor: 'pointer',
    zIndex: 1002,
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      color: '#202332'
    }
  },
  content: {
    padding: 15,
    color: '#989FA6'
  },
  '@media (max-width: 767px)': {
    rootOpen: {
      background:
        'linear-gradient(90deg, rgba(32,35,50,0.8) 0%, rgba(240,240,240,1) 3%, rgba(255,255,255,1) 100%)'
      // transform: 'translateX(0)'
    },
    rootSmall: {
      background: 'transparent'
    },
    toggleButton: {},
    root: {
      position: 'fixed',
      top: '60px',
      right: '0',
      height: '100vh',
      transform: 'translateX(0.1%)',
      transition: 'transform 300ms linear',
      willChange: 'transform',
      zIndex: 1001,
      '&$rootOpen': {
        transform: 'translateX(0)'
      },
      '&$rootSmall': {
        background: 'transparent',
        maxWidth: 32,
        height: 80,
        '& $toggleButton': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px 10px',
          width: '100%'
        }
      }
    }
  }
}
