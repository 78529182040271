import { put, call, select } from 'redux-saga/effects'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import { error403, pageIsLoading } from 'domain/loading'
import * as Router from 'domain/router'
import { FormValuesCampaignForManager } from 'types/managerTypes'
import { convertDomains, transformCampaignUpdateValues } from './helpers'

export function* ensureGetCampaignsList() {
  try {
    const userToken = yield select(M.userToken)
    if (!userToken) return

    const {
      data: { data }
    } = yield call(Api.getCampaignsM, {
      headers: { Authorization: `Bearer ${userToken}` }
    })
    yield put({ type: Actions.getCampaignsList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.getCampaignsList.failure,
      err
    })
  }
}

export function* ensureGetDomainList() {
  try {
    const userToken = yield select(M.userToken)
    if (!userToken) return

    const {
      data: { data }
    } = yield call(Api.getDomainsM, {
      headers: { Authorization: `Bearer ${userToken}` }
    })
    yield put({ type: Actions.getDomainsList.success, payload: I.fromJS(convertDomains(data)) })
  } catch (err) {
    yield put({
      type: Actions.getDomainsList.failure,
      err
    })
  }
}

export function* ensureGetCampaignData() {
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  if (!headers) return
  const {
    path,
    params: { id }
  } = yield select(Router.matchRoutes)
  if (id === 'new') {
    yield put({ type: Actions.getCampaignData.success, payload: I.fromJS({}) })
    return
  }
  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.getCampaignDataM, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.getCampaignData.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({ type: Actions.getCampaignData.success, payload: I.fromJS({}) })
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.getCampaignData.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensurePutCampaign({
  payload
}: {
  payload: FormValuesCampaignForManager
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: pageIsLoading, payload: true })
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.putCampaignM, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      data: transformCampaignUpdateValues(payload)
    })
    yield put({ type: Actions.putCampaign.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.putCampaign.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensurePostCampaign({
  payload
}: {
  payload: FormValuesCampaignForManager
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: pageIsLoading, payload: true })
  try {
    const {
      data: { data }
    } = yield call(Api.postCampaignM, {
      headers: { Authorization: `Bearer ${headers}` },
      data: transformCampaignUpdateValues(payload)
    })
    yield put({ type: Actions.postCampaign.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.postCampaign.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureCheckCampaignName(props: { payload: { name: string }; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.checkCampaignNameM, {
      headers: { Authorization: `Bearer ${headers}` },
      params: props.payload
    })
    yield put({ type: Actions.checkCampaignName.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.checkCampaignName.FAILURE,
      err
    })
  }
}
