import React, { Component } from 'react'
// libs
import cx from 'classnames'
// components
import Logo from 'components/svgComponent/Logo'
import ActionsGroup from 'components/common/ActionsGroup'
import Menu from 'components/svgComponent/Menu'
import SearchMenu from 'components/svgComponent/SearchMenu'
import { Button } from 'components/Button'
// redux
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Push, push } from 'connected-react-router'
// types
import { ButtonTypes, ComposeType } from 'types/common'
import { Classes } from 'jss'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'

interface Props {
  classes: Classes
  style?: React.CSSProperties
  push?: Push
}

interface State {
  showMenu: boolean
}

const linksName = [
  { name: 'About Us', to: '/' },
  { name: 'Search jobs', to: '/jobs' },
  { name: 'Support', to: '/' },
  { name: 'FAQ', to: '/' },
  { name: 'Contact information', to: '/' }
]

class Header extends Component<Props, State> {
  state = {
    showMenu: false
  }

  showHideMenu = () => {
    this.setState(prevState => ({ showMenu: !prevState.showMenu }))
  }

  onClick = (path: string, isCandidate: boolean = false) => {
    if (this.props.push) {
      this.props.push({
        pathname: path,
        state: {
          isCandidate
        }
      })
    }
  }

  logIn = () => {
    this.onClick('/log-in')
  }

  signUp = () => {
    this.onClick('/sign-up')
  }

  signUpWithParamTrue = () => {
    this.onClick('/sign-up', true)
  }

  getVideoPath = () => {
    const hostname = window.location.hostname

    if (hostname === 'cyber.skilent.com' || hostname === 'cyber.dev.skilent.com') {
      return '/assets/videos/cyber-red-lights.mp4'
    } else if (hostname === 'medical.skilent.com' || hostname === 'medical.dev.skilent.com') {
      return '/assets/videos/medical_bg.mp4'
    } else {
      return window.innerWidth > 767.98
        ? '/assets/videos/3872414-sd.mp4'
        : '/assets/videos/3872414-lowres.mp4'
    }
  }

  render() {
    const { classes } = this.props
    const { showMenu } = this.state
    const videoPath = this.getVideoPath()
    return (
      <header className={classes.header}>
        <div className={classes.gradient} />
        <video
          preload="auto"
          autoPlay={true}
          muted={true}
          loop={true}
          id="myVideo"
          className={classes.videoClass}
        >
          <source src={videoPath} type="video/mp4" />
        </video>

        <Logo className={classes.headerLogo} />
        <div className={classes.menuIcons}>
          <Menu onClick={this.showHideMenu} />
          {/* <SearchMenu /> */}
        </div>
        <div className={cx(classes.headerNav, { showMenu })}>
          {linksName.map((item, i) => (
            <Button isLink={true} to={item.to} key={i} label={item.name} />
          ))}
        </div>
        <div className={classes.headerAuth}>
          <Button label="log in" onClick={this.logIn} />
          <Button type={ButtonTypes.Auth} label="sign up" onClick={this.signUp} />
        </div>

        <div className={classes.headerTitle}>
          <h1>
            Skilent <br /> is all you need <br /> to find a job or candidates
          </h1>
          <ActionsGroup>
            <Button
              type={ButtonTypes.Default}
              label="find a job"
              onClick={this.signUpWithParamTrue}
            />
            <Button type={ButtonTypes.Primary} label="find candidates" onClick={this.signUp} />
          </ActionsGroup>
        </div>
        {/*<div className={classes.bottomHeader}>*/}
        {/*  <div>*/}
        {/*    <div>140+</div>*/}
        {/*    <div>Professional Hiring managers with extensive experience</div>*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <div>500+</div>*/}
        {/*    <div>Verified Recruiters with proper education requirements</div>*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <div>23 000</div>*/}
        {/*    <div>jobs on average are closed every month</div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </header>
    )
  }
}

export default compose<ComposeType<Props>>(
  connect(
    null,
    {
      push
    }
  ),
  injectSheet(sheet)
)(Header)
