import moment from 'moment'
import { DomainM, FormValuesCampaignForManager } from 'types/managerTypes'
import React from 'react'
import { CityI, SelectListT } from '../../../types/common'
import I from 'immutable'

export const transformCampaignUpdateValues = (values: FormValuesCampaignForManager) => {
  const {
    name,
    createdAt,
    description,
    image,
    label,
    isPublic,
    city,
    proximity,
    chatPropertyId,
    chatWidgetId,
    expiresAt,
    skills,
    domains
  } = values
  const cityId = city ? city.value : null
  const proximityValue = cityId ? (proximity ? proximity : null) : null

  return {
    name: name ? name : null,
    createdAt: createdAt || new Date(),
    image: image ? image : null,
    label: label ? label : null,
    description: description ? description : null,
    cityId,
    proximity: proximityValue,
    isPublic: isPublic ? isPublic : false,
    expiresAt: expiresAt && moment(expiresAt).startOf('day'),
    chatPropertyId: chatPropertyId ? chatPropertyId : null,
    chatWidgetId: chatWidgetId ? chatWidgetId : null,
    skills:
      skills &&
      Array.isArray(skills) &&
      skills
        .map(skill => skill.id || undefined)
        .filter(s => !!s)
        .join(','),
    domains: domains && domains.map(d => d.value)
  }
}

export function convertDomains(domains: DomainM[]): SelectListT {
  return I.fromJS(
    domains.map((domain: DomainM) => ({
      value: domain.id,
      label: domain.name
    }))
  )
}
