import L from 'leaflet'
import { MapControl, withLeaflet, LeafletContext } from 'react-leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import ReactDOM from 'react-dom'
import React from 'react'

interface CustomControlGPSProps {
  leaflet: LeafletContext
  onMyLocationChosen?: (lat: number, lng: number) => void
}

class CustomControlGPS extends MapControl<CustomControlGPSProps> {
  createLeafletElement(props: CustomControlGPSProps) {
    const customControl = L.Control.extend({
      onAdd: () => {
        const container = L.DomUtil.create(
          'div',
          'leaflet-bar leaflet-control leaflet-control-custom'
        )
        container.style.backgroundColor = 'white'
        container.style.height = '30px'
        container.style.width = '30px'
        container.style.cursor = 'pointer'
        container.style.display = 'flex'
        container.style.alignItems = 'center'
        container.style.justifyContent = 'center'
        container.title = 'Show My Location'

        ReactDOM.render(<FontAwesomeIcon icon={faLocationDot} />, container)

        L.DomEvent.disableClickPropagation(container)

        L.DomEvent.on(container, 'click', () => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              position => {
                const { latitude, longitude } = position.coords
                if (props.onMyLocationChosen) {
                  props.onMyLocationChosen(latitude, longitude)
                } else {
                  console.error('onMyLocationChosen callback is not defined.')
                }
              },
              error => {
                console.error('Error obtaining location:', error)
              }
            )
          } else {
            console.error('Geolocation is not supported by this browser.')
          }
        })

        return container
      }
    })

    return new customControl({ position: 'topright' })
  }
}

export default withLeaflet(CustomControlGPS)
