import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const checkAuth = asyncAction('auth/CHECK')
export const confirmation = asyncAction('auth/CONFIRMATION')
export const signIn = asyncAction('auth/SIGN_IN')
export const signUpManager = asyncAction('auth/SIGN_UP_MANAGER')
export const signUpCandidate = asyncAction('auth/SIGN_UP_CANDIDATE')
export const signUpRecruiter = asyncAction('auth/SIGN_UP_RECRUITER')
export const signOut = asyncAction('auth/SIGN_OUT')
export const recoveryPassword = asyncAction('auth/RECOVERY_PASSWORD')
export const updatePassword = asyncAction('auth/UPDATE_PASSWORD')
export const recruiterAccept = asyncAction('auth/RECRUITER_ACCEPT')
export const acceptRecommendJob = asyncAction('auth/ACCEPT_RECOMMEND_JOB')
export const superManagerRequest = asyncAction('auth/SUPER_MANAGER_REQUEST')

export const checkUserEmail = createRoutine('recruiter/CHECK_USER_EMAIL')
export const checkUserEmailPromiseCreator = promisifyRoutine(checkUserEmail)
