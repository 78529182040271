import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faFilter } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'
import injectSheet from 'react-jss'
import { Classes } from 'jss'
import { sheet } from './sheet'

interface Props {
  classes: Classes
  children: React.ReactNode
  isOpen: boolean
  toggleSidebar: () => void
  maxWidth: number
}

interface State {
  menuIsOpen: boolean
}

class RightSidebar extends Component<Props, State> {
  state = {
    menuIsOpen: this.props.isOpen
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ menuIsOpen: this.props.isOpen })
    }
  }

  toggleMenu = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen }, () => {
      this.props.toggleSidebar()
    })
  }

  render() {
    const { classes, children, maxWidth } = this.props
    const { menuIsOpen } = this.state

    return (
      <div
        className={cx(classes.root, {
          [classes.rootSmall]: !menuIsOpen,
          [classes.rootOpen]: menuIsOpen
        })}
        style={{ maxWidth: menuIsOpen ? maxWidth : 32 }}
      >
        <button className={classes.toggleButton} onClick={this.toggleMenu}>
          <FontAwesomeIcon icon={menuIsOpen ? faChevronRight : faFilter} size="2x" />
        </button>
        {menuIsOpen && <div className={classes.content}>{children}</div>}
      </div>
    )
  }
}

export default injectSheet(sheet)(RightSidebar)
