import L from 'leaflet'
import { MapControl, withLeaflet, LeafletContext } from 'react-leaflet'

interface CustomControlProps {
  leaflet: LeafletContext
  onLoadMore: () => void
}

class CustomControl extends MapControl<CustomControlProps> {
  createLeafletElement(props: CustomControlProps) {
    const customControl = L.Control.extend({
      onAdd: () => {
        const container = L.DomUtil.create(
          'div',
          'leaflet-bar leaflet-control leaflet-control-custom'
        )
        container.style.backgroundColor = 'white'
        // container.style.width = '70px'
        container.style.height = '20px'
        container.style.padding = '3px 5px'
        container.style.cursor = 'pointer'
        container.innerHTML = '<b class="your-icon-class">Load more</b>'
        L.DomEvent.disableClickPropagation(container)
        L.DomEvent.on(container, 'click', props.onLoadMore)

        return container
      }
    })

    return new customControl({ position: 'topright' })
  }
}

export default withLeaflet(CustomControl)
