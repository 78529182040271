// list of routes related to user role
import { ReactNode } from 'react'

interface RoutesType {
  [index: string]: Array<{ path: string; component: () => Promise<ReactNode> }>
}

export const routes: RoutesType = {
  super_manager: [
    { path: '/super_manager/profile', component: () => import('pages/SuperManager/Profile') },
    { path: '/super_manager/dashboard', component: () => import('pages/SuperManager/Dashboard') },
    {
      path: '/super_manager/profile',
      component: () => import('pages/SuperManager/Candidates/View')
    },
    {
      path: '/super_manager/jobs/view/:id',
      component: () => import('pages/SuperManager/Jobs/View/index')
    },
    {
      path: '/super_manager/clients-jobs',
      component: () => import('pages/SuperManager/Jobs/ClientsJobs/index')
    },
    {
      path: '/super_manager/jobs/:id/candidates/:candidateId',
      component: () => import('pages/SuperManager/Candidates/View/index')
    },
    { path: '/super_manager/jobs', component: () => import('pages/SuperManager/Jobs/List') },
    { path: '/super_manager/clients', component: () => import('pages/SuperManager/Clients/List') },
    {
      path: '/super_manager/managers/:id',
      component: () => import('pages/SuperManager/ProfileView/Manager')
    },
    { path: '/notifications', component: () => import('pages/Notifications/index') }
  ],
  manager: [
    { path: '/manager/profile', component: () => import('pages/Manager/Profile/index') },
    { path: '/manager/dashboard', component: () => import('pages/Manager/Dashboard/index') },
    { path: '/manager/jobs/view/:id', component: () => import('pages/Manager/Jobs/View/index') },
    {
      path: '/manager/jobs/:id/candidates/:candidateId',
      component: () => import('pages/Manager/Candidates/View/index')
    },
    {
      path: '/manager/candidates/:candidateId',
      component: () => import('pages/Manager/Candidates/View/index')
    },
    {
      path: '/manager/jobs/:id/candidates',
      component: () => import('pages/Manager/Candidates/List/index')
    },
    { path: '/manager/jobs/:id', component: () => import('pages/Manager/Jobs/Edit/index') },
    { path: '/manager/jobs/clone/:id', component: () => import('pages/Manager/Jobs/Edit/index') },
    { path: '/manager/jobs', component: () => import('pages/Manager/Jobs/List/index') },
    { path: '/manager/clients', component: () => import('pages/Manager/Clients/List/index') },
    {
      path: '/manager/recruiters/:id',
      component: () => import('pages/Manager/ProfileView/Recruiter')
    },
    { path: '/manager/recruiters', component: () => import('pages/Manager/Recruiters/List/index') },
    {
      path: '/manager/managers/:id',
      component: () => import('pages/Manager/ProfileView/Manager')
    },
    { path: '/manager/calendar', component: () => import('pages/Manager/Calendar/index') },
    {
      path: '/manager/campaigns/view/:id',
      component: () => import('pages/Manager/Campaigns/View/index')
    },
    {
      path: '/manager/campaigns/edit/:id',
      component: () => import('pages/Manager/Campaigns/Edit/index')
    },
    { path: '/manager/campaigns', component: () => import('pages/Manager/Campaigns/List/index') },
    { path: '/manager/post-new-job', component: () => import('pages/Manager/Profile/index') },
    { path: '/manager/add-client', component: () => import('pages/Manager/Profile/index') },
    { path: '/notifications', component: () => import('pages/Notifications/index') },
    { path: '/zoom', component: () => import('pages/Zoom') },
    { path: '/manager/recruiters-jobs', component: () => import('pages/Manager/Recruiters/Jobs') },
    { path: '/manager/clients-jobs', component: () => import('pages/Manager/Clients/Jobs') },
    {
      path: '/manager/recruiters-clients',
      component: () => import('pages/Manager/Recruiters/ClientsList')
    }
  ],
  recruiter: [
    { path: '/recruiter/profile', component: () => import('pages/Recruiter/Profile/index') },
    { path: '/recruiter/dashboard', component: () => import('pages/Recruiter/Dashboard/index') },
    {
      path: '/recruiter/jobs/:jobsId/candidates/:id',
      component: () => import('pages/Recruiter/Candidates/View/index')
    },
    {
      path: '/recruiter/jobs/view/:id',
      component: () => import('pages/Recruiter/Jobs/View/index')
    },
    { path: '/recruiter/jobs', component: () => import('pages/Recruiter/Jobs/List/index') },
    {
      path: '/recruiter/managers/:id',
      component: () => import('pages/Recruiter/ProfileView/Manager')
    },
    {
      path: '/recruiter/recruiters/:id',
      component: () => import('pages/Recruiter/ProfileView/Recruiter')
    },
    {
      path: '/recruiter/candidates/add',
      component: () => import('pages/Recruiter/Candidates/Add/index')
    },
    {
      path: '/recruiter/candidates/add-from-resume/:id',
      component: () => import('pages/Recruiter/Candidates/AddFromResume/index')
    },
    {
      path: '/recruiter/candidates/:id/edit',
      component: () => import('pages/Recruiter/Candidates/Edit/index')
    },
    {
      path: '/recruiter/candidates/:id',
      component: () => import('pages/Recruiter/Candidates/View/index')
    },
    {
      path: '/recruiter/candidates',
      component: () => import('pages/Recruiter/Candidates/List/index')
    },
    {
      path: '/recruiter/resumes/add',
      component: () => import('pages/Recruiter/Resumes/Add')
    },
    {
      path: '/recruiter/resumes',
      component: () => import('pages/Recruiter/Resumes/List/index')
    },
    { path: '/recruiter/messages', component: () => import('pages/Messages/index') },
    { path: '/notifications', component: () => import('pages/Notifications/index') },
    { path: '/zoom', component: () => import('pages/Zoom') }
  ],
  candidate: [
    { path: '/candidate/dashboard', component: () => import('pages/Candidate/Dashboard/index') },
    { path: '/candidate/profile', component: () => import('pages/Candidate/Profile/index') },
    {
      path: '/candidate/managers/:id',
      component: () => import('pages/Candidate/ProfileView/Manager')
    },
    {
      path: '/candidate/candidates/:id',
      component: () => import('pages/Candidate/ProfileView/Candidate')
    },
    {
      path: '/candidate/recruiters/:id',
      component: () => import('pages/Candidate/ProfileView/Recruiter')
    },
    {
      path: '/candidate/jobs/view/:id',
      component: () => import('pages/Candidate/Jobs/View/index')
    },
    { path: '/candidate/jobs', component: () => import('pages/Candidate/Jobs/List/index') },
    { path: '/candidate/calendar', component: () => import('pages/Candidate/Calendar/index') },
    { path: '/candidate/messages', component: () => import('pages/Messages/index') },
    { path: '/notifications', component: () => import('pages/Notifications/index') },
    { path: '/zoom', component: () => import('pages/Zoom') }
  ]
}
