import React, { Component, createRef } from 'react'
import {
  Circle,
  Map as LeafletMap,
  Marker,
  Popup,
  TileLayer,
  Tooltip as LeafletTooltip
} from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import iconBlue from './marker-icon-blue.png'
import iconViolet from './marker-icon-violet.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import {
  ATTRIBUTION_TEXT,
  convertDistance,
  isValidPosition,
  OPENSTREETMAP_URL,
  Position
} from 'lib/map'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'react-leaflet-markercluster/dist/styles.min.css'
import { Push } from 'connected-react-router'
import { toNumber } from 'lodash'
import CustomControl from './CustomControl'
import CustomControlGPS from './CustomControlGPS'

const DefaultIcon = L.icon({
  iconUrl: iconBlue,
  iconSize: [25, 41],
  iconAnchor: [13, 41],
  shadowUrl: iconShadow,
  popupAnchor: [0, -26]
})

const CenterIcon = L.icon({
  iconUrl: iconViolet,
  iconSize: [25, 41],
  iconAnchor: [13, 41],
  shadowUrl: iconShadow,
  // shadowSize: [,]
  // shadowAnchor: [,], // point of the shadow which will correspond to marker's location
  popupAnchor: [0, -26]
})

L.Marker.prototype.options.icon = DefaultIcon

interface PositionWithLink extends Position {
  link?: string
  city?: string
  onClick?: () => void
}

interface MapListProps {
  positions: PositionWithLink[]
  centerPoint?: PositionWithLink
  proximity?: number
  zoom?: number
  push?: Push
  style?: any
  handleLoadMore?: () => void
  tooltipBehavior?: 'hover' | 'click'
  onMyLocationChosen?: (lat: number, lng: number) => void
}

class MapListView extends Component<MapListProps> {
  mapRef = createRef<LeafletMap>()
  state = {
    visibleTooltips: {} as { [key: number]: boolean },
    userLocation: null as Position | null
  }

  componentDidMount() {
    this.fitMapToMarkers()

    if (this.props.onMyLocationChosen) {
      const leafletMap = this.mapRef && this.mapRef.current && this.mapRef.current.leafletElement
      if (leafletMap) {
        leafletMap.on('click', this.onMapClick)
      }
    }
  }

  componentWillUnmount() {
    if (this.props.onMyLocationChosen) {
      const leafletMap = this.mapRef && this.mapRef.current && this.mapRef.current.leafletElement
      if (leafletMap) {
        leafletMap.off('click', this.onMapClick)
      }
    }
  }

  componentDidUpdate(prevProps: MapListProps, prevState: any) {
    const isChangedPositions =
      JSON.stringify(this.props.positions) !== JSON.stringify(prevProps.positions)
    const isChangedCenterPoint =
      JSON.stringify(this.props.centerPoint) !== JSON.stringify(prevProps.centerPoint)
    const isChangedStyle = JSON.stringify(this.props.style) !== JSON.stringify(prevProps.style)
    if (isChangedPositions || isChangedCenterPoint || isChangedStyle) {
      this.fitMapToMarkers()
    }
  }

  onMapClick = (e: L.LeafletMouseEvent) => {
    const { onMyLocationChosen } = this.props

    if (onMyLocationChosen) {
      const { lat, lng } = e.latlng
      this.onMyLocationChosen(lat, lng)
    }
  }

  handlePopupClick = (pos: PositionWithLink) => {
    if (pos.link && this.props.push) {
      this.props.push(pos.link)
    }
    if (pos.onClick) {
      pos.onClick()
    }
  }

  fitMapToMarkers() {
    const positions = [this.props.centerPoint].concat(this.props.positions)
    const validPositions = positions.filter(isValidPosition)
    if (this.mapRef.current && validPositions.length) {
      const leafletMap = this.mapRef.current.leafletElement
      const bounds = new L.LatLngBounds(
        validPositions.reduce((Arr: any[], pos) => {
          return pos ? [...Arr, [pos.lat, pos.lng]] : Arr
        }, [])
      )
      try {
        leafletMap.fitBounds(bounds, { padding: [50, 50] })
      } catch (err) {
        console.debug(err)
      }
    }
  }

  renderCenterPoint() {
    const centerPoint = this.props.centerPoint
    if (!centerPoint || !isValidPosition(centerPoint)) return null

    return (
      <Marker position={new L.LatLng(centerPoint.lat, centerPoint.lng)} icon={CenterIcon}>
        {centerPoint.markerText && (
          <Popup>
            <h5
              onClick={() => this.handlePopupClick(centerPoint)}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {centerPoint.markerText}
            </h5>
          </Popup>
        )}
      </Marker>
    )
  }

  getDistanceFromCenter = (lat?: any, lng?: any) => {
    // const { centerPoint } = this.props
    const centerPoint = this.state.userLocation || this.props.centerPoint
    if (!centerPoint || !isValidPosition(centerPoint) || !isValidPosition({ lat, lng })) return null

    const center = new L.LatLng(centerPoint.lat, centerPoint.lng)
    const point = new L.LatLng(lat, lng)
    return center.distanceTo(point) // distance in meters
  }

  renderMarkers() {
    const { positions, tooltipBehavior = 'hover' } = this.props

    return positions.filter(isValidPosition).map((pos, index) => {
      const distance = this.getDistanceFromCenter(pos.lat, pos.lng)
      let distanceTextKm = ''
      let distanceTextMiles = ''
      if (distance !== null) {
        const { kilometers, miles } = convertDistance(distance)
        distanceTextKm = `${kilometers}`
        distanceTextMiles = `${miles}`
      }

      const showTooltip = this.state.visibleTooltips[index] || tooltipBehavior === 'hover'
      const isStickyTooltip = tooltipBehavior === 'hover'

      return (
        <Marker
          key={index}
          position={new L.LatLng(pos.lat, pos.lng)}
          onClick={() => {
            if (tooltipBehavior === 'click') {
              this.setState({
                visibleTooltips: { ...this.state.visibleTooltips, [index]: !showTooltip }
              })
            }
            this.handlePopupClick(pos)
          }}
        >
          {showTooltip && pos.markerText && (
            <LeafletTooltip
              sticky={isStickyTooltip}
              interactive={!isStickyTooltip}
              permanent={!isStickyTooltip}
            >
              <div style={{ minWidth: 300, cursor: pos.link ? 'pointer' : 'default' }}>
                <p>
                  <span style={{ fontWeight: 'bolder' }}>{pos.markerText}</span>
                </p>
                {pos.markerDesc && (
                  <p
                    style={{ width: '100%', whiteSpace: 'normal' }}
                    dangerouslySetInnerHTML={{ __html: pos.markerDesc }}
                  />
                )}
                {distance !== null && (
                  <p>
                    {pos.city && (
                      <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
                        <strong>{`${pos.city}`}</strong>
                        <br />
                      </span>
                    )}
                    {distance !== 0 && (
                      <>
                        <span>{distanceTextKm} km away</span>
                        <br />
                        <span>
                          <i>(~{distanceTextMiles} miles)</i>
                        </span>
                      </>
                    )}
                  </p>
                )}
                {tooltipBehavior === 'click' && (
                  <button onClick={() => this.handlePopupClick(pos)}>Show</button>
                )}
              </div>
            </LeafletTooltip>
          )}
        </Marker>
      )
    })
  }

  renderCircle() {
    const centerPoint = this.props.centerPoint
    const proximity = this.props.proximity || 0
    if (!centerPoint || !isValidPosition(centerPoint) || !proximity || toNumber(proximity) < 10) {
      return null
    }

    return (
      <Circle
        center={new L.LatLng(centerPoint.lat, centerPoint.lng)}
        radius={toNumber(proximity) * 1000}
        stroke={true}
        color="blue"
        weight={2}
        dashArray="5, 10"
        fill={true}
        fillColor="gray"
      >
        <LeafletTooltip sticky={true}>Proximity: {toNumber(proximity)} km</LeafletTooltip>
      </Circle>
    )
  }

  onMyLocationChosen = (lat: number, lng: number) => {
    const { onMyLocationChosen } = this.props
    if (onMyLocationChosen) {
      onMyLocationChosen(lat, lng)
    }
    this.setState({ userLocation: { lat, lng } })
    const leafletMap = this.mapRef && this.mapRef.current && this.mapRef.current.leafletElement
    if (leafletMap) {
      leafletMap.flyTo(new L.LatLng(lat, lng), 13, {
        animate: true,
        duration: 1.5
      })

      leafletMap.once('zoomend', () => {
        if (leafletMap.getZoom() > leafletMap.getMinZoom()) {
          leafletMap.zoomOut(1)
        }
      })
    }
  }

  renderUserLocation() {
    const { userLocation } = this.state
    if (!userLocation || !isValidPosition(userLocation)) return null

    return (
      <Marker position={new L.LatLng(userLocation.lat, userLocation.lng)} icon={DefaultIcon}>
        <Popup>
          <span>Your Location</span>
        </Popup>
      </Marker>
    )
  }

  render() {
    const defaultZoom = this.props.zoom || 13
    const mapKey = JSON.stringify(this.props.style)

    return (
      <LeafletMap
        key={mapKey}
        ref={this.mapRef}
        zoom={defaultZoom}
        minZoom={2}
        maxZoom={13}
        style={{
          transition: 'height 0.5s ease-in-out',
          height: '100vh',
          width: '100%',
          ...this.props.style
        }}
        className="markercluster-map"
      >
        <TileLayer url={OPENSTREETMAP_URL} attribution={ATTRIBUTION_TEXT} />
        <MarkerClusterGroup>
          {this.renderCenterPoint()}
          {this.renderMarkers()}
          {this.renderUserLocation()}
        </MarkerClusterGroup>
        {this.renderCircle()}
        {this.props.handleLoadMore && <CustomControl onLoadMore={this.props.handleLoadMore} />}
        {this.props.onMyLocationChosen && (
          <CustomControlGPS onMyLocationChosen={this.onMyLocationChosen} />
        )}
      </LeafletMap>
    )
  }
}

export default MapListView
