import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
// components
import Layout from 'pages/Layout'
import TermsAndConditions from 'pages/TermsAndConditions/index'
import NavBar from 'pages/NavBar'
import Sidebar from 'pages/Sidebar'
import Loader from 'components/Loader'
import ModalLogin from 'components/ModalAuth/index'
import LogIn from './Auth/LogIn'
import SignUp from './Auth/SignUp'
import CreatePassword from './Auth/CreatePassword'
import CheckConfirmation from './Auth/CheckConfirmation'
import RecruiterInvitation from './Auth/RecruiterInvitation'
import ResetPassword from './Auth/ResetPassword'
import Support from 'pages/Support'
import GuestJobs from 'pages/Guest/Jobs'
import CandidateInvitation from './Auth/CandidateInvite'
// redux
import { Redirect, Route, Switch, withRouter } from 'react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { routes } from 'domain/router/routes'
import {
  isAuthorizedSelector,
  userSelector,
  recruiterAccept,
  acceptRecommendJob,
  signOut
} from 'domain/env'
import { pageIsLoadingSelector } from 'domain/loading'
// types
import { ComposeType, UserType } from 'types/common'
import { Classes } from 'jss'
import { StateInterface } from 'types/state'
// styles
import injectSheet from 'react-jss'
import { sheet } from './sheet'
// utils
import AsyncRoute from 'lib/asyncRoute'
import ReactTooltip from 'react-tooltip'
import { getStorageItem } from 'lib/storage'

interface Props {
  classes: Classes
  style?: React.CSSProperties
  isAuthorized: boolean | null
  pageIsLoading: boolean
  user: UserType
  acceptInvitation: (data: object) => void
  acceptRecommendation: (data: object) => void
  signOut: () => void
}

class App extends PureComponent<Props, { isOpenMobileSidebar: boolean }> {
  state = {
    isOpenMobileSidebar: false
  }

  componentDidUpdate() {
    window.addEventListener('storage', e => {
      const tokens = getStorageItem('tokens', '')
      if (!tokens) {
        this.props.signOut()
      }
    })
  }

  confirmText = () => (
    <>
      <Layout />
      <ModalLogin modalComponent={{ component: CheckConfirmation }} />
    </>
  )

  logIn = () => (
    <>
      <Layout />
      <ModalLogin modalComponent={{ component: LogIn }} />
    </>
  )

  signUp = () => (
    <>
      <Layout />
      <ModalLogin modalComponent={{ component: SignUp }} />
    </>
  )

  createPassword = () => (
    <>
      <Layout />
      <ModalLogin modalComponent={{ component: CreatePassword }} />
    </>
  )

  inviteRecruiter = () => (
    <>
      <Layout />
      <ModalLogin
        modalComponent={{
          component: RecruiterInvitation,
          props: {
            acceptInvitation: this.props.acceptInvitation
          }
        }}
      />
    </>
  )

  inviteCandidate = () => (
    <>
      <Layout />
      <ModalLogin modalComponent={{ component: CandidateInvitation }} />
    </>
  )

  acceptRecommendations = () => (
    <>
      <Layout />
      <ModalLogin
        modalComponent={{
          component: RecruiterInvitation,
          props: {
            acceptInvitation: this.props.acceptRecommendation
          }
        }}
      />
    </>
  )

  resetPassword = () => (
    <>
      <Layout />
      <ModalLogin modalComponent={{ component: ResetPassword }} />
    </>
  )

  toggleSidebarMenu = () => {
    this.setState(prevState => ({ isOpenMobileSidebar: !prevState.isOpenMobileSidebar }))
  }

  render() {
    const { isAuthorized, classes, user, pageIsLoading } = this.props
    const { isOpenMobileSidebar } = this.state
    const role = user.get('role')
    const redirectPath = `/${role}/dashboard`

    if (isAuthorized === null) {
      return null
    }
    return !isAuthorized ? (
      <React.Fragment>
        <main className={classes.main}>
          <Loader show={pageIsLoading} />
          <Switch>
            <Route exact={true} path="/" component={Layout} />
            <Route path="/terms_and_conditions" component={TermsAndConditions} />
            <Route path="/account/confirmation" component={() => null} />
            <Route path="/account/check-confirmation" component={this.confirmText} />
            <Route path="/log-in" component={this.logIn} />
            <Route path="/sign-up" component={this.signUp} />
            <Route path="/account/password-reset" component={this.createPassword} />
            <Route path="/recruiter-invite" component={this.inviteRecruiter} />
            <Route path="/guest/job/:id" component={this.acceptRecommendations} />
            <Route path="/reset-password" component={this.resetPassword} />
            <Route path="/guest/jobs" component={GuestJobs} />
            <Route path="/jobs" component={GuestJobs} />
            <Route path="/candidate-invite" component={this.inviteCandidate} />
            <Redirect to="/" />
          </Switch>
        </main>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <main className={classes.root}>
          <Loader show={pageIsLoading} />
          <div className={classes.rootContainer}>
            <Sidebar
              isOpenMobileSidebar={isOpenMobileSidebar}
              toggleSidebarMenu={this.toggleSidebarMenu}
            />
            <NavBar toggleSidebarMenu={this.toggleSidebarMenu} />
            <Switch>
              <Route exact={true} path="/support" component={Support} />
              {routes[role].map((router, idx) => {
                return <AsyncRoute key={idx} path={router.path} import={router.component} />
              })}
              <Redirect to={redirectPath} />
            </Switch>
            {isOpenMobileSidebar && (
              <div className={classes.openSidebarOverlay} onClick={this.toggleSidebarMenu} />
            )}
          </div>
          <ReactTooltip multiline={true} place="bottom" effect="solid" />
        </main>
      </React.Fragment>
    )
  }
}

export default compose<ComposeType<Props>>(
  withRouter,
  connect(
    (state: StateInterface) => ({
      pageIsLoading: pageIsLoadingSelector(state),
      isAuthorized: isAuthorizedSelector(state),
      user: userSelector(state)
    }),
    { acceptInvitation: recruiterAccept, acceptRecommendation: acceptRecommendJob, signOut }
  ),
  injectSheet(sheet)
)(App)
