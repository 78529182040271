import React from 'react'
import { SvgType } from 'components/svgComponent/Svg'
import Dashboard from 'components/svgComponent/Dashboard'
import Jobs from 'components/svgComponent/Jobs'
import Clients from 'components/svgComponent/Clients'
import Recruiters from 'components/svgComponent/Recruiters'
import Calendar from 'components/svgComponent/Calendar'
import Messages from 'components/svgComponent/Messages'
import SearchCandidates from 'components/svgComponent/SearchCandidates'
import Notification from 'components/svgComponent/notification/MenuIcon'
import Zoom from 'components/svgComponent/ZoomIcon'

interface ServerRoles {
  [index: string]: string
  manager: string
  recruiter: string
  recruiterWithOutManagers: string
  candidate: string
  super_manager: string
}
export const ServerRoles: ServerRoles = {
  manager: 'manager',
  recruiter: 'recruiter',
  recruiterWithOutManagers: 'recruiterWithOutManagers',
  candidate: 'candidate',
  super_manager: 'super_manager'
}

export const Roles: { [index: string]: string } = {
  manager: 'Hiring Manager',
  recruiter: 'Recruiter',
  candidate: 'Candidate',
  super_manager: 'Super Hire manager'
}

export interface ItemRoleType {
  icon: (props: SvgType) => JSX.Element
  name: string
  to: string
  count?: number
}

interface ObjectType {
  [index: string]: ItemRoleType[]
}

export const RolesMenu: ObjectType = {
  super_manager: [
    {
      icon: Dashboard,
      name: 'Dashboard',
      to: '/super_manager/dashboard'
    },
    {
      icon: Jobs,
      name: 'Jobs',
      to: '/super_manager/jobs'
    },
    {
      icon: Clients,
      name: 'Clients',
      to: '/super_manager/clients'
    },
    {
      icon: Notification,
      name: 'Notifications',
      to: '/notifications'
    }
  ],
  manager: [
    {
      icon: Dashboard,
      name: 'Dashboard',
      to: '/manager/dashboard'
    },
    {
      icon: Jobs,
      name: 'Jobs',
      to: '/manager/jobs'
    },
    {
      icon: Clients,
      name: 'Clients',
      to: '/manager/clients'
    },
    {
      icon: Recruiters,
      name: 'Recruiters',
      to: '/manager/recruiters'
    },
    {
      icon: Messages,
      name: 'Campaigns',
      to: '/manager/campaigns'
    },
    {
      icon: Calendar,
      name: 'Calendar',
      to: '/manager/calendar'
    },
    {
      icon: Notification,
      name: 'Notifications',
      to: '/notifications'
    }
    // {
    //   icon: Zoom,
    //   name: 'Zoom',
    //   to: '/zoom'
    // }
  ],
  recruiterWithOutManagers: [
    {
      icon: Dashboard,
      name: 'Dashboard',
      to: '/recruiter/dashboard'
    },
    {
      icon: SearchCandidates,
      name: 'Search candidates',
      to: '/recruiter/candidates'
    },
    {
      icon: Messages,
      name: 'Messages',
      to: '/recruiter/messages'
    },
    {
      icon: Notification,
      name: 'Notifications',
      to: '/notifications'
    }
  ],
  recruiter: [
    {
      icon: Dashboard,
      name: 'Dashboard',
      to: '/recruiter/dashboard'
    },
    {
      icon: Jobs,
      name: 'Jobs',
      to: '/recruiter/jobs'
    },
    {
      icon: SearchCandidates,
      name: 'Search candidates',
      to: '/recruiter/candidates'
    },
    {
      icon: Recruiters,
      name: 'Resumes',
      to: '/recruiter/resumes'
    },
    {
      icon: Messages,
      name: 'Messages',
      to: '/recruiter/messages'
    },
    {
      icon: Notification,
      name: 'Notifications',
      to: '/notifications'
    }
    // {
    //   icon: Zoom,
    //   name: 'Zoom',
    //   to: '/zoom'
    // }
  ],
  candidate: [
    {
      icon: Dashboard,
      name: 'Dashboard',
      to: '/candidate/dashboard'
    },
    {
      icon: Jobs,
      name: 'Jobs',
      to: '/candidate/jobs'
    },
    {
      icon: Calendar,
      name: 'Calendar',
      to: '/candidate/calendar'
    },
    {
      icon: Messages,
      name: 'Messages',
      to: '/candidate/messages'
    },
    {
      icon: Notification,
      name: 'Notifications',
      to: '/notifications'
    }
    // {
    //   icon: Zoom,
    //   name: 'Zoom',
    //   to: '/zoom'
    // }
  ],
  guest: [
    {
      icon: Jobs,
      name: 'Jobs',
      to: '/jobs'
    },
    {
      icon: Calendar,
      name: 'Calendar',
      to: '/log-in'
    },
    {
      icon: Messages,
      name: 'Messages',
      to: '/log-in'
    },
    {
      icon: Notification,
      name: 'Notifications',
      to: '/log-in'
    }
  ]
}
