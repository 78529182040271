import { all, takeLatest, take, fork, call, select } from 'redux-saga/effects'
import * as Actions from 'domain/recruiter/importedCandidate/actions'
import * as Sagas from 'domain/recruiter/importedCandidate/sagas'
import { checkUserEmail } from 'domain/env'
import { ensureCheckUserEmail } from 'domain/env/sagas'

export function* profilePage() {
  yield all([
    fork(getParsedResume),
    fork(putProfile),
    fork(postProfile),
    fork(addAvatarProfile),
    fork(deleteResumeProfile),
    fork(checkCandidateEmail),
    fork(postResume)
  ])
}

function* checkCandidateEmail() {
  yield takeLatest(checkUserEmail.TRIGGER, ensureCheckUserEmail)
}

function* getParsedResume() {
  yield call(Sagas.ensureGetParsedResume)
  yield takeLatest(Actions.getParsedResume.TRIGGER, Sagas.ensureGetParsedResume)
  yield take(Actions.getParsedResume.SUCCESS)
}

function* postProfile() {
  yield takeLatest(Actions.candidatesAdd.TRIGGER, Sagas.ensureAddProfileIC)
  yield take(Actions.candidatesAdd.SUCCESS)
}

function* putProfile() {
  yield takeLatest(Actions.candidatesEdit.TRIGGER, Sagas.ensureEditProfileIC)
  yield take(Actions.candidatesEdit.SUCCESS)
}

function* addAvatarProfile() {
  yield takeLatest(Actions.addCandidatesAvatar.type, Sagas.ensureAddAvatarIC)
  yield take(Actions.addCandidatesAvatar.success)
}

function* deleteResumeProfile() {
  yield takeLatest(Actions.candidatesDeleteResume.type, Sagas.ensureDeleteResumeIC)
  yield take(Actions.candidatesDeleteResume.success)
}

function* postResume() {
  yield takeLatest(Actions.resumeAdd.TRIGGER, Sagas.ensureParseResume)
  yield take(Actions.resumeAdd.SUCCESS)
}
