import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import { pageIsLoading } from 'domain/loading'
import * as C from 'domain/manager/campaigns'

export function* campaignView() {
  yield all([fork(getCampaign), fork(initialLoad)])
}

function* getCampaign() {
  yield takeLatest(C.getCampaignData.type, C.ensureGetCampaignData)
  yield take(C.getCampaignData.success)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([call(C.ensureGetCampaignData)])
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
