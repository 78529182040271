import { put, call, select } from 'redux-saga/effects'
import { PropsProfile, PropsResumeAdd } from 'domain/recruiter/importedCandidate/types'
import {
  buildResumeFormData,
  transformDataForRequest
} from 'domain/recruiter/importedCandidate/helpers'
import { error403, pageIsLoading } from 'domain/loading'
import { transformCandidate } from './helpers'
import I from 'immutable'
import Api from 'domain/api'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'

interface Props {
  payload: FormData
  type: string
}

export function* ensureGetImportedCandidate() {
  yield put({
    type: Actions.importedCandidateProfile.success,
    payload: I.fromJS({})
  })
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  if (!headers || !id) return

  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.getImportedCandidateProfile, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({
      type: Actions.importedCandidateProfile.success,
      payload: transformCandidate(I.fromJS(data))
    })
  } catch (err) {
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.importedCandidateProfile.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureAddAvatarIC({ payload }: Props) {
  const headers = yield select(M.userToken)
  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  if (!headers || !id) return

  try {
    const {
      data: { data }
    } = yield call(Api.postAvatarImportedCandidates, {
      headers: { Authorization: `Bearer ${headers}` },
      id,
      data: payload
    })
    yield put({ type: Actions.addCandidatesAvatar.success, payload: I.fromJS(data) })
    yield call(ensureGetImportedCandidate, { payload: true })
  } catch (err) {
    yield put({
      type: Actions.addCandidatesAvatar.failure,
      err
    })
  }
}

export function* ensureAddProfileIC({ payload }: PropsProfile) {
  const headers = yield select(M.userToken)

  if (!headers || !payload) return

  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.postImportedCandidates, {
      headers: {
        Authorization: `Bearer ${headers}`
      },
      data: transformDataForRequest(payload)
    })
    yield put({ type: Actions.candidatesAdd.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.candidatesAdd.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureParseResume({ payload }: PropsResumeAdd) {
  const headers = yield select(M.userToken)
  if (!headers || !payload) return

  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.parseResumeImportedCandidates, {
      headers: {
        Authorization: `Bearer ${headers}`
      },
      data: buildResumeFormData(payload)
    })
    yield put({ type: Actions.resumeAdd.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.resumeAdd.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureParseResumeAndCreate({ payload }: PropsResumeAdd) {
  const headers = yield select(M.userToken)
  if (!headers || !payload) return

  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.parseResumeAndCreateImportedCandidates, {
      headers: {
        Authorization: `Bearer ${headers}`
      },
      data: buildResumeFormData(payload)
    })

    yield put({ type: Actions.resumeAddCreate.SUCCESS, payload: data })
  } catch (err) {
    yield put({
      type: Actions.resumeAddCreate.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureEditProfileIC({ payload }: PropsProfile) {
  const headers = yield select(M.userToken)
  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  if (!headers || !id) return

  try {
    yield put({ type: pageIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.putImportedCandidates, {
      headers: {
        Authorization: `Bearer ${headers}`
      },
      id,
      data: transformDataForRequest(payload)
    })
    yield put({ type: Actions.candidatesEdit.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.candidatesEdit.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureDeleteResumeIC() {
  const headers = yield select(M.userToken)
  const {
    params: { id }
  } = yield select(Router.matchRoutes)

  if (!headers || !id) return

  try {
    const {
      data: { data }
    } = yield call(Api.deleteResumeImportedCandidates, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({ type: Actions.candidatesDeleteResume.success, payload: I.fromJS(data) })
    yield call(ensureGetImportedCandidate, { payload: true })
  } catch (err) {
    yield put({
      type: Actions.candidatesDeleteResume.failure,
      err
    })
  }
}

export function* ensureGetParsedResume() {
  yield put({
    type: Actions.getParsedResume.SUCCESS,
    payload: I.fromJS({})
  })
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  if (!headers || !id) return

  try {
    yield put({ type: pageIsLoading, payload: true })
    const { data } = yield call(Api.getParsedResume, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })

    yield put({ type: Actions.getParsedResume.SUCCESS, payload: I.fromJS(data.data) })
  } catch (err) {
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.getParsedResume.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
