import { all, takeLatest, take, fork, call, put } from 'redux-saga/effects'
import { pageIsLoading } from 'domain/loading'
import * as D from 'domain/candidate/dashboard'
import * as C from 'domain/candidate/candidates'

export function* dashboard() {
  yield all([
    fork(initialLoad),
    fork(assignedJobs),
    fork(appliedJobs),
    fork(favoriteJobs),
    fork(getCandidates),
    fork(getInvitedCandidates),
    fork(getPendingCandidates),
    fork(deleteCandidate),
    fork(declineInvitation),
    fork(resendInvitation)
  ])
}

function* assignedJobs() {
  yield takeLatest(D.candidateAssignedJobs.type, D.ensureAssignedJobs)
  yield take(D.candidateAssignedJobs.success)
}

function* appliedJobs() {
  yield takeLatest(D.candidateAppliedJobs.type, D.ensureAppliedJobs)
  yield take(D.candidateAppliedJobs.success)
}

function* favoriteJobs() {
  yield takeLatest(D.candidateFavoriteJobs.type, D.ensureFavoriteJobs)
  yield take(D.candidateFavoriteJobs.success)
}

function* getCandidates() {
  yield takeLatest(D.getInvitedCandidates.type, D.ensureCandidatesList)
  yield take(D.getInvitedCandidates.success)
}

function* getInvitedCandidates() {
  yield takeLatest(C.invitedCandidatesList.type, C.ensureInvitedCandidatesList)
  yield take(C.invitedCandidatesList.success)
}

function* getPendingCandidates() {
  yield takeLatest(D.getPendingCandidates.type, D.ensurePendingCandidatesList)
  yield take(D.getPendingCandidates.success)
}

function* deleteCandidate() {
  yield takeLatest(C.deleteCandidate.TRIGGER, C.ensureDeleteCandidate)
}

function* declineInvitation() {
  yield takeLatest(C.declineInvitationToCandidate.TRIGGER, C.ensureDeclineInvitationToCandidate)
}

function* resendInvitation() {
  yield takeLatest(C.resendInvitationToCandidates.type, C.ensureResendInvitationToCandidate)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([
      call(D.ensureAssignedJobs),
      call(D.ensureAppliedJobs),
      call(D.ensureFavoriteJobs),
      call(D.ensureCandidatesList),
      call(D.ensurePendingCandidatesList),
      call(C.ensureInvitedCandidatesList)
    ])
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
