import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { pageIsLoading } from 'domain/loading'
import * as C from 'domain/manager/campaigns'

export function* campaignEdit() {
  yield all([
    fork(getCampaign),
    fork(putCampaign),
    fork(postCampaign),
    fork(initialLoad),
    fork(checkCampaignName)
  ])
}

function* getCampaign() {
  yield takeLatest(C.getCampaignData.type, C.ensureGetCampaignData)
  yield take(C.getCampaignData.success)
}

function* putCampaign() {
  yield takeLatest(C.putCampaign.TRIGGER, C.ensurePutCampaign)
  yield take(C.putCampaign.SUCCESS)
  yield put(push('/manager/campaigns'))
}

function* postCampaign() {
  yield takeLatest(C.postCampaign.TRIGGER, C.ensurePostCampaign)
  yield take(C.postCampaign.SUCCESS)
  yield put(push('/manager/campaigns'))
}

function* checkCampaignName() {
  yield takeLatest(C.checkCampaignName.TRIGGER, C.ensureCheckCampaignName)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([call(C.ensureGetCampaignData), call(C.ensureGetDomainList)])
    yield put({ type: 'profile/CLEAR_CITIES' })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
