import { put, call, select } from 'redux-saga/effects'
import { skeleton, pageIsLoading, isLoading } from 'domain/loading'
import { jobsGuestSelector } from './selectors'
import * as Actions from './actions'
import Api from 'domain/api'
import I from 'immutable'
import * as Router from '../router'

interface Params {
  order?: string
  filter?: FilterType
  page?: number
}

interface FilterType {
  countryId?: number
  cityId?: number
  tagIds?: string[]
  rateFrom?: string
  rateTo?: string
  rateCurrency?: string
  salaryFrom?: string
  salaryTo?: string
  salaryCurrency?: string
  relocation?: boolean
  employmentType?: string[]
  clearanceType?: string[]
  travel?: boolean
  citizenship?: string[]
}

export function* ensureGetJobsGuest(props?: { payload?: Params; type: string }) {
  const params = (props && props.payload) || {}
  const matchRoutes = yield select(Router.matchRoutes)
  let campaign
  if (matchRoutes && matchRoutes.params) {
    campaign = matchRoutes.params.campaign
  }

  if (!props || !props.payload) {
    yield put({ type: pageIsLoading, payload: true })
    yield put({ type: skeleton, payload: true })
  }
  if (props && props.payload && props.payload.page === 1) {
    yield put({ type: skeleton, payload: true })
  }
  try {
    const { data } = yield call(Api.getJobsGuest, {
      params: {
        ...params,
        campaign
      }
    })
    const page = params.page
    const total = data.meta.total
    const prevState = yield select(jobsGuestSelector)
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.guestNewJobsList.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.guestJobsList.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.guestJobsList.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
    yield put({ type: skeleton, payload: false })
    yield put({ type: isLoading, payload: false })
  }
}

export function* ensureGetCampaignsGuest() {
  try {
    const hostname = window.location.hostname
    const params = hostname ? { hostname } : {}
    const { data } = yield call(Api.getCampaignsGuest, { params })
    yield put({ type: Actions.guestCampaignsList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.guestCampaignsList.failure,
      err
    })
  }
}
