import { put, call, select } from 'redux-saga/effects'
import { dateTimeRangeSelector, DateTimeRangeT } from 'domain/constants'
import { Sort, ResolveT } from 'types/common'
import Api from 'domain/api'
import I from 'immutable'
import * as M from 'domain/env'
import * as Actions from './actions'

interface Params {
  page?: number
  order?: Sort
  order_by?: string | null
  range?: string
}

export function* ensureAssignedJobs(props?: { payload?: Params & ResolveT; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = (props && props.payload) || { range: range.get('week') }

  try {
    const { data } = yield call(Api.candidateAssignedJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })

    yield put({ type: Actions.candidateAssignedJobs.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.candidateAssignedJobs.failure,
      err
    })
  } finally {
    // tslint:disable-next-line:no-unused-expression
    props && props.payload && props.payload.resolve && props.payload.resolve(undefined)
  }
}

export function* ensureAppliedJobs(props?: { payload?: Params & ResolveT; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return

  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = (props && props.payload) || { range: range.get('week') }

  try {
    const { data } = yield call(Api.candidateAppliedJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })

    yield put({ type: Actions.candidateAppliedJobs.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.candidateAppliedJobs.failure,
      err
    })
  } finally {
    // tslint:disable-next-line:no-unused-expression
    props && props.payload && props.payload.resolve && props.payload.resolve(undefined)
  }
}

export function* ensureFavoriteJobs(props?: { payload?: Params & ResolveT; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return

  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = (props && props.payload) || { range: range.get('week') }

  try {
    const { data } = yield call(Api.candidateFavoriteJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })

    yield put({ type: Actions.candidateFavoriteJobs.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.candidateFavoriteJobs.failure,
      err
    })
  } finally {
    // tslint:disable-next-line:no-unused-expression
    props && props.payload && props.payload.resolve && props.payload.resolve(undefined)
  }
}

export function* ensureCandidatesList(props?: { payload?: Params & ResolveT; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return

  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = (props && props.payload) || { range: range.get('week') }
  try {
    const { data } = yield call(Api.getCandidatesAcceptedInvites, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: Actions.getInvitedCandidates.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.getInvitedCandidates.failure,
      err
    })
  } finally {
    // tslint:disable-next-line:no-unused-expression
    props && props.payload && props.payload.resolve && props.payload.resolve(undefined)
  }
}

export function* ensurePendingCandidatesList(props?: {
  payload?: Params & ResolveT
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return

  const range: DateTimeRangeT = yield select(dateTimeRangeSelector)
  const params = (props && props.payload) || { range: range.get('week') }
  try {
    const { data } = yield call(Api.getPendingCandidates, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    yield put({ type: Actions.getPendingCandidates.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.getPendingCandidates.failure,
      err
    })
  } finally {
    // tslint:disable-next-line:no-unused-expression
    props && props.payload && props.payload.resolve && props.payload.resolve(undefined)
  }
}
