import { Styles } from 'react-jss'

export const sheet: Styles = {
  leftBlock: {},
  mainBlock: {},
  blockTitle: {},
  title: {
    flex: '1'
  },
  topSecret: {},
  rateInfo: {},
  rateBlock: {},
  workType: {},
  location: {
    flex: '1'
  },
  info: {},
  row: {},
  spheres: {},
  saveJob: {
    cursor: 'pointer',
    color: '#989FA6'
  },
  highlight: {},
  travel: {
    flex: '0'
  },
  relocation: {
    flex: '0'
  },
  tooltipText: {
    visibility: 'hidden',
    width: '130px',
    background: '#080910',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: '14px',
    letterSpacing: '0.01em',
    textTransform: 'capitalize',
    textAlign: 'center',
    color: '#FAFAFA',
    padding: '5px 0',
    position: 'absolute',
    bottom: '130%',
    right: '-20%',
    marginLeft: -55,
    zIndex: '1',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      right: '7.5px',
      marginLeft: '-5px',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: 'black transparent transparent transparent'
    }
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFFFFF',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 15,
    lineHeight: '21px',
    letterSpacing: '0.01em',
    padding: '5px',
    color: '#080910',
    overflow: 'hidden',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    border: '1px solid transparent',
    borderBottom: '1px solid #DEDEDE',
    transition: 'border-color 0.25s linear, height 0.2s linear',
    '&$highlight': {
      background: 'linear-gradient(to bottom, #e0e8ff, #ffffff)'
    },
    '&:hover': {
      borderColor: '#286EFA',
      '& $title': {
        color: '#286EFA'
      },
      '& $saveJob > svg': {
        color: '#080910'
      }
    },
    '& > $leftBlock': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      cursor: 'default',
      '& > $mainBlock': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > $blockTitle': {
          marginRight: '2%',
          width: '100%',
          '& > $title': {
            fontSize: 17,
            marginTop: 0,
            marginBottom: '0.5em',
            wordBreak: 'break-all'
          },
          '& > $topSecret': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 'bold',
            lineHeight: '19px',
            wordBreak: 'break-all',
            '& > span:last-child:not(:first-child)': {
              marginLeft: 32,
              background: '#FEF5F5',
              border: '1px dashed #BC2121',
              boxSizing: 'border-box',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: 13,
              lineHeight: '18px',
              letterSpacing: '0.03em',
              textTransform: 'capitalize',
              color: '#E14A4A',
              padding: '5px 8px',
              whiteSpace: 'nowrap'
            }
          },
          '& > $location': {
            color: '#989FA6',
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            paddingLeft: 20,
            margin: 0,
            alignItems: 'center',
            minHeight: 29,
            '&:before': {
              content: ` url("${require('components/svg/location.svg')}")`,
              position: 'absolute',
              top: 0,
              left: 0
            }
          }
        },
        '& > $rateInfo': {
          border: '1px dashed #989FA6',
          fontStyle: 'italic',
          fontWeight: 'normal',
          fontSize: 15,
          lineHeight: '19px',
          letterSpacing: '0.01em',
          color: '#989FA6',
          padding: '10px',
          height: 'fit-content',
          '& > $rateBlock': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '& > p': {
              whiteSpace: 'nowrap'
            },
            '& > p:first-child': {
              marginRight: 16
            }
          },
          '& > $workType': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& > svg': {
              marginRight: 12
            }
          }
        }
      },
      '& > $info': {
        marginTop: '0.5em',
        marginBottom: '0.3em',
        fontWeight: 'normal',
        lineHeight: '20px',
        wordBreak: 'break-all',
        '& > span': {
          fontWeight: 'bold'
        }
      },
      '& > $row': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        textTransform: 'uppercase',
        '& > $spheres': {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          '& > span': {
            textTransform: 'capitalize',
            background: '#F0F0F0',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: 13,
            lineHeight: '18px',
            letterSpacing: '0.03em',
            color: '#080910',
            margin: '0 10px 10px 0',
            padding: '4px 10px',
            wordBreak: 'break-all'
          }
        },
        '& > div:last-child': {
          margin: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          '& > svg:first-child': {
            margin: 0,
            marginRight: '15px'
          },
          '& > $travel, & > $relocation': {
            position: 'relative',
            margin: 0,
            marginRight: '15px',
            '&:hover $tooltipText': {
              visibility: 'visible'
            }
          },
          '& > $travel': {
            marginRight: 16
          }
        }
      }
    }
  },
  compactRate: {},
  salary: {},
  locationName: {},
  compactRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px',
    cursor: 'pointer',
    alignItems: 'center',
    '& $title': {
      flex: '5'
    },
    '& $location': {
      flex: '3'
    },
    '& $salary': {
      flex: '2'
    },
    '& $locationName': {
      marginBlock: 0
    },
    '& $travel, & $relocation': {
      flex: '0.5',
      marginBlock: 0,
      position: 'relative',
      '& $tooltipText': {
        '&:after': {
          display: 'none'
        }
      },
      '&:hover > $tooltipText': {
        bottom: '50%',
        left: '-55px',
        visibility: 'visible',
        fontSize: 10,
        lineHeight: '12px'
      }
    },
    '& > p, & > div': {
      marginRight: 10
    },
    '& > $location': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > $locationName': {
        position: 'relative',
        color: '#989FA6',
        paddingLeft: 20,
        '&:before': {
          content: ` url("${require('components/svg/location.svg')}")`,
          position: 'absolute',
          top: -7,
          left: 0
        }
      }
    },
    '& > $salary': {
      '& > $compactRate': {
        margin: 'auto',
        '& > span': {
          fontSize: 12
        }
      }
    }
  },
  applyButton: {
    margin: 'auto',
    marginTop: '5px',
    marginBottom: '10px',
    minWidth: '20%',
    padding: '10px 20px',
    backgroundColor: '#286EFA',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '5px',
    fontWeight: 'bold',
    fontSize: 20,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1A5FC4'
    }
  }
}
