import I, { List } from 'immutable'
import * as A from './profile/actions'
import * as P from './points/actions'
import * as J from 'domain/recruiter/jobs'
import * as RR from 'domain/recruiter/parsedResumes'
import * as MProfile from 'domain/recruiter/managerProfileView'
import * as RProfile from 'domain/recruiter/recruiterProfileView'
import * as C from 'domain/recruiter/candidates'
import * as D from 'domain/recruiter/dashboard'
import * as Calendar from 'domain/recruiter/calendar'
import * as Env from 'domain/env'
import * as IC from 'domain/recruiter/importedCandidate'
import { Action } from 'redux'
import { ImmutableMap } from 'types/common'
import { IClosedJobs } from 'pages/Dashboard/types'
import { JobsListRecruiter } from 'types/job/jobTypesRecruiter'
import { CandidateListT, ParsedResumeItemI, ParsedResumesListI } from 'types/recruitersTypes'

interface ActionType extends Action {
  payload: any
}

const State = I.fromJS({
  managers: I.List(),
  jobsListData: I.List(),
  candidatesListData: I.List(),
  jobsSelfAssigned: I.List(),
  dashboard: I.Map({
    jobsInProgress: I.Map({}),
    jobsCounters: I.Map({}),
    closedJobs: I.Map({}),
    recruiterSkills: I.Map({}),
    clients: I.Map({}),
    statistics: I.Map({}),
    topRecruiters: I.List()
  }),
  calendar: {
    eventsList: I.List(),
    event: I.Map(),
    candidatesList: I.List(),
    nearestEventId: null,
    candidateJobs: I.List()
  },
  jobsCount: 0,
  jobsToAssigned: [],
  jobData: I.Map(),
  candidateJobs: {
    data: [],
    meta: {}
  },
  jobsListForFilter: [],
  candidatesListMeta: {},
  resume: I.Map(),
  parsedResumes: I.List(),
  parsedResumesMeta: I.Map()
})

export const reducer = {
  recruiter(state = State, action: ActionType) {
    switch (action.type) {
      case J.getJobsCount.success:
        return state.set('jobsCount', action.payload)
      case A.HMList.success:
        return state.set('managers', action.payload)
      case J.newJobsList.success:
        return state
          .set('jobsListData', action.payload.get('data'))
          .set('jobsListMeta', action.payload.get('meta'))
          .set('jobsCount', 0)
      case J.jobsList.success:
        return state
          .update('jobsListData', (u: JobsListRecruiter) => u.concat(action.payload.get('data')))
          .set('jobsListMeta', action.payload.get('meta'))
      case J.jobData.success:
        return state.set('jobData', action.payload)
      case P.points.success:
        return state.set('points', action.payload)
      case P.receivedPoints.success:
        return state.set('receivedPoints', action.payload)
      case MProfile.managerProfileView.success:
        return state.set('managerProfile', action.payload)
      case RProfile.recruiterProfile.success:
        return state.set('recruiterProfile', action.payload)
      case C.candidatesNewList.success:
        return state
          .set('candidatesListData', action.payload.get('data'))
          .set('candidatesListMeta', action.payload.get('meta'))
      case C.candidates.success:
        return state
          .update('candidatesListData', (u: List<CandidateListT>) =>
            u.concat(action.payload.get('data'))
          )
          .set('candidatesListMeta', action.payload.get('meta'))
      case C.candidateFavoriteFromList.success:
        return state.update('candidatesListData', (u: List<CandidateListT>) =>
          u.map(item => {
            if (item.get('id') === action.payload.get('id')) {
              return item.update('isFavorite', () => action.payload.get('value'))
            }
            return item
          })
        )
      case C.candidateProfile.success:
        return state.set('candidateProfile', action.payload)
      case IC.importedCandidateProfile.success:
        const parsedResume = action.payload.get('parsedResume')
        return state
          .set('importedCandidateProfile', action.payload)
          .set('resume', parsedResume ? I.fromJS(parsedResume) : I.Map())
      case IC.getParsedResume.success().type:
        return state.set('resume', action.payload)
      case IC.resumeAdd.success().type:
        return state.set('resume', action.payload)
      case IC.resumeAdd.failure().type:
        return state.set('resume', I.Map())
      // case IC.resumeAddCreate.success().type:
      //   return state.set('resume', I.Map())
      case RR.newParsedResumesList.success:
        return state
          .set('parsedResumes', action.payload.get('data'))
          .set('parsedResumesMeta', action.payload.get('meta'))
          .set('parsedResumesCount', 0)
      case RR.parsedResumesList.success:
        return state
          .update('parsedResumes', (u: ParsedResumesListI) => u.concat(action.payload.get('data')))
          .set('parsedResumesMeta', action.payload.get('meta'))
      case RR.removeResumeFromQueue.success:
      case RR.forceAddResumeToQueue.success:
        return state.update('parsedResumes', (parsedResumes: ParsedResumesListI) =>
          parsedResumes.map(resume => {
            const updatedResume = action.payload.find(
              (ur: ImmutableMap<ParsedResumeItemI>) => ur.get('id') === resume.get('id')
            )
            return updatedResume ? resume.merge(updatedResume) : resume
          })
        )
      case C.jobsToAssignCandidate.success:
        return state.set('jobsToAssigned', action.payload)
      case C.selfAssignJobCandidate.SUCCESS:
        return state.set('jobsSelfAssigned', action.payload)
      case C.jobsListForFilter.success:
        return state.set('jobsListForFilter', action.payload.get('data'))
      case C.candidateJobs.success:
        return state
          .updateIn(['candidateJobs', 'data'], (u: any) =>
            action.payload.getIn(['meta', 'page']) === 1
              ? action.payload.get('data')
              : u.concat(action.payload.get('data'))
          )
          .setIn(['candidateJobs', 'meta'], action.payload.get('meta'))
      case D.recruiterJobsInProgress.success:
        return state.setIn(['dashboard', 'jobsInProgress'], action.payload)
      case D.recruiterJobsCounters.success:
        return state.setIn(['dashboard', 'jobsCounters'], action.payload)
      case D.recruiterNewClosedJobs.success:
        return state.setIn(['dashboard', 'closedJobs'], action.payload)
      case D.recruiterClosedJobs.success:
        return state.updateIn(
          ['dashboard', 'closedJobs', 'data'],
          (u: List<ImmutableMap<IClosedJobs>>) => u.concat(action.payload.get('data'))
        )
      case D.recruiterRecruiterSkills.success:
        return state.setIn(['dashboard', 'recruiterSkills'], action.payload)
      case D.recruiterClients.success:
        return state.setIn(['dashboard', 'clients'], action.payload)
      case D.recruiterStatistics.success:
        return state.setIn(['dashboard', 'statistics'], action.payload)
      case D.recruiterTopRecruiters.success:
        return state.setIn(['dashboard', 'topRecruiters'], action.payload)
      case Calendar.recruiterEvent.success:
        return state.setIn(['calendar', 'event'], action.payload)
      case Calendar.recruiterEventsList.success:
        return state.setIn(['calendar', 'eventsList'], action.payload)
      case Calendar.recruiterCandidatesList.success:
        return state.setIn(['calendar', 'candidatesList'], action.payload)
      case Calendar.nearestEventId.success:
        return state.setIn(['calendar', 'nearestEventId'], action.payload)
      case Calendar.candidateJobsList.success:
        return state.setIn(['calendar', 'candidateJobs'], action.payload)
      case Env.signOut.success:
        return State

      default:
        return state
    }
  }
}
