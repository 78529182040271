import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const importedCandidateProfile = asyncAction('profile/IMPORTED_CANDIDATE_PROFILE')
export const addCandidatesAvatar = asyncAction('profile/ADD_CANDIDATES_AVATAR')
export const candidatesDeleteResume = asyncAction('recruiter/CANDIDATES_DELETE_RESUME')
export const getParsedResume = createRoutine('recruiter/GET_PARSED_RESUME')
export const candidatesAdd = createRoutine('recruiter/CANDIDATES_ADD')
export const resumeAdd = createRoutine('recruiter/RESUME_ADD')
export const resumeAddPromiseCreator = promisifyRoutine(resumeAdd)
export const resumeAddCreate = createRoutine('recruiter/RESUME_ADD_CREATE')
export const resumeAddCreatePromiseCreator = promisifyRoutine(resumeAddCreate)
export const candidatesAddPromiseCreator = promisifyRoutine(candidatesAdd)
export const candidatesEdit = createRoutine('recruiter/CANDIDATES_EDIT')
export const candidatesEditPromiseCreator = promisifyRoutine(candidatesEdit)
