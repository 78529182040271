import { all, call, fork } from 'redux-saga/effects'
import * as C from 'domain/manager/campaigns'

export function* campaignsList() {
  yield all([fork(getCampaignsList)])
}

function* getCampaignsList() {
  yield call(C.ensureGetCampaignsList)
}
