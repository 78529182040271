import { isNumber, toNumber } from 'lodash'
import { LatLng } from 'leaflet'

export const ATTRIBUTION_TEXT =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
export const OPENSTREETMAP_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
const MIN_LATITUDE = -90
const MAX_LATITUDE = 90
const MIN_LONGITUDE = -180
const MAX_LONGITUDE = 180

export interface Position {
  lat: number
  lng: number
  markerText?: string
  markerDesc?: any
}

export const isValidPosition = (pos?: any | null) => {
  return pos && isValidLat(pos.lat) && isValidLng(pos.lng)
}

export const isValidCoordinate = (value: any): value is number => {
  const numberValue = toNumber(value)
  return isNumber(numberValue) && !isNaN(numberValue)
}

export const isValidLat = (lat: any): boolean => {
  const numberLat = toNumber(lat)
  return (
    lat !== null &&
    isNumber(numberLat) &&
    !isNaN(numberLat) &&
    numberLat >= MIN_LATITUDE &&
    numberLat <= MAX_LATITUDE
  )
}

export const isValidLng = (lng: any): boolean => {
  const numberLng = toNumber(lng)
  return (
    lng !== null &&
    isNumber(numberLng) &&
    !isNaN(numberLng) &&
    numberLng >= MIN_LONGITUDE &&
    numberLng <= MAX_LONGITUDE
  )
}

export const getValidLocation = (pos?: {
  lat?: number | string
  lng?: number | string
}): LatLng | undefined => {
  if (!pos) return undefined
  const { lat, lng } = pos
  const latitude = toNumber(lat)
  const longitude = toNumber(lng)

  if (isValidLat(latitude) && isValidLng(longitude)) {
    return new LatLng(latitude, longitude)
  }

  return undefined
}

export const convertDistance = (distanceInMeters: number) => {
  const distanceInKm = distanceInMeters / 1000 // Convert meters to kilometers
  const distanceInMiles = distanceInMeters / 1609.34 // Convert meters to miles
  return {
    kilometers: distanceInKm.toFixed(1),
    miles: distanceInMiles.toFixed(1)
  }
}

export const convertKmToMiles = (distanceInKm: number) => distanceInKm / 1.609
